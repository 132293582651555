.payment{
    margin: 60px auto;
    width: 100%;
    padding: 0 40px;

    @include tablet{
        margin: 0px;
        padding: 0;
    }

    &__amt{
        margin: 0 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include tablet{
            margin: 0px;
            display: flex;
            flex-direction: column-reverse;
        }

        &__head{
            width: 60%;
            border: 1px solid $color-main-blue;
            border-radius: 21px;
            display: flex;
            justify-content: space-between;

            @include tablet{
                width: 100%;
                border: 1px solid $color-main-blue;
                border-radius: 15px;
                display: flex;
                justify-content: space-between;
                margin-top: 25px;
            }
            
            div{
                padding: 0px 20px;

                &:first-child{
                    width: fit-content;
                    background-color: $color-blue-dark;
                    color: $color-txt-white;
                    border-radius: 15px;
                    border: none;
                    line-height: 40px;
                    outline: none;

                    @include tablet{
                        border-radius: 15px;
                        padding: 0px 10px 0px 10px;
                    }
                }

                // &:last-child{
                //     @include tablet{
                //         margin-right: 12px;
                //     }
                // }

            }
            
            h3{
                font-weight: 600;
                font-size: 20px;
                line-height: 32px;
                padding: 12px 0 0 0;

                @include tablet{
                    font-size: 14px;
                    line-height: 21px;
                    padding: 11px 0 0 0;
                }
            }

            p{
                font-size: 23px;
                font-weight: 500;
                line-height: 31px;
                padding: 0 0 11px 0;

                @include tablet{
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 25px;
                    padding: 0 0 11px 0;
                }
            }
        }

        &__chart{
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 30px;

            &-labels{
                display: flex;
                flex-direction: column;
                column-gap: 12px;
                align-items: center;
                font-size: 16px;
                line-height: 27px;

                @include tablet{
                    line-height: 20px;
                }


                img{
                    width: 12px;
                    height: 12px;
                }

                span{
                    margin: 6px 0;
                }
            }
        }
    }

    &__cards{
        padding: 40px 0;
        display: flex;
        justify-content: center;
        column-gap: 30px;
        align-items: stretch;

        @include tablet{
            flex-direction: column-reverse;
            justify-content: space-between;
            padding: 28px 0px 0px;
        }

        &__card{
            background-color: $color-main-back;
            border-radius: 20px;
            width: 30%;
            padding: 20px 25px 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include tablet{
                width: 100%;
                padding: 20px 18px 25px;
                margin: 0px 0px 25px;

            }

            h3{
                font-weight: 700;
                font-size: 15px;
                // line-height: 29px;
                border: 1px solid $color-blue-light;
                width: fit-content;
                padding: 7px 15px;
                border-radius: 10px;

                @include tablet{
                    border-radius: 5px;
                }
            }

            h4{
                font-weight: 600;
                font-size: 23px;
                line-height: 29px;
                padding: 18px 0px 10px;

                @include tablet{
                    font-size: 22px;
                    line-height: 28px;
                    padding: 14px 0px 6px;
                }
            }

            p{
                font-weight: 400;
                font-size: 15px;
            }

            hr{
                background-color: $color-dark-grey !important;
                margin-block-start: 17px;
                margin-block-end: 17px;

                @include tablet{
                    margin-block-start: 6px;    
                }
            }

            span{
                font-size: 15px;
                font-weight: 400;
                // line-height: 20px;
            }


            &_ibm{
                display: flex;
                align-items: center;
                margin-bottom: 25px;

                @include tablet{
                    margin-bottom: 20px;
                }

                img:first-child{
                    height: 20px;
                    margin-right: 12px;

                }
                img:nth-child(2){
                    height: 21px;
                    margin-right: 12px;

                    @include tablet{
                        height: 18px;
                    }

                }
            }

            &_ns{
                display: flex;
                align-items: center;
                margin-bottom: 25px;

                @include tablet{
                    margin-bottom: 18px;
                }

                img:first-child{
                    height: 20px;
                    margin-right: 12px;

                }
                img:nth-child(2){
                    height: 25px;
                    margin-right: 12px;

                }
            }

            &_iim{
                display: flex;
                align-items: center;
                margin-bottom: 25px;

                @include tablet{
                    margin-bottom: 8px;
                }

                img:first-child{
                    height: 20px;
                    margin-right: 12px;

                }
                img:nth-child(2){
                    height: 37px;
                    margin-right: 12px;

                    @include tablet{
                        height: 34px;
                    }

                }
            }

            &_spotify{
                display: flex;
                align-items: center;
                margin-bottom: 25px;

                @include tablet{
                    margin-bottom: 15px;
                }

                img:first-child{
                    height: 20px;
                    margin-right: 12px;
                }

                img:nth-child(2){
                    height: 41px;
                    margin-right: 12px;

                    @include tablet{
                        height: 35px;
                    }

                }
            }

            &-save{
                color: $color-green;
            }

            button{
                width: 100%;
                margin-top: 0px;
                padding: 9px 0;
                line-height: unset;
                font-size: 18px;
                font-weight: 600;

                @include tablet{
                    font-weight: 600;
                    font-size: 18px;
                    margin-top: 8px;
                    padding: 8px;
                    line-height: 28px;
                    border-radius: 6px;
                }
            }
            
            .pay{
                @include tablet{
                    margin: 0 10px;
                }
            }

        }
    }

    &__history{
        // margin: 0px 21px 35px;

        @include tablet{
            margin: 0 0 50px;
        }

        h3{
            font-size: 21px;
            font-weight: 600;
            line-height: 29px;
            margin-bottom: 35px;

            @include tablet{
                margin-bottom: 18px;
            }
        }

        &__container{
            border: 0.5px solid $color-grey;
            border-radius: 10px;
            
            &_head{
                display: grid;
                grid-template-columns: auto auto auto;
                background-color: $color-blue-medium;
                text-align: center;
                padding: 19px 10px;
                font-size: 20px;
                font-weight: 500;
                line-height: 26px;
                border-radius: 10px 10px 0px 0px;

                @include tablet{
                    padding: 12px 10px;

                }
            }

            &_body{
                display: grid;
                grid-template-columns: auto auto auto;
                padding: 17px 0px;
                background-color: $color-background-light-black;
                text-align: center;
                border-bottom: 1px solid $color-grey;

                img{
                    height: 15px;
                    margin-left: 7px;
                }

                a{
                    color: $color-txt-white;
                }

            }
        }
    }
}

