.popup{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(86, 86, 86);
    background-color: rgba(86, 86, 86, 0.71);
    z-index: 999999;

    &__container{
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 23px 19px;
        border: 1px solid #888;
        width: 20%; /* Could be more or less, depending on screen size */
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background: #26282C;
        border-radius: 10px;
        flex-direction: column;

        @include tablet{
            width: 90%;
            margin: 45% auto;
        }

        label{
            font-weight: 500;
            font-size: 16px;
        }

        input{
            padding: 14px 16px;
            border: 1px solid rgba(42, 112, 254, 0.6);
            width: 100%;
            font-size: 15px;
            background: $color-main-black;
            border-radius: 8px;
            color: $color-txt-white;
            margin-top: 7px;
            font-family: $font-Jakarta;
            margin-top: 20px;
        }

        button{
            padding: 8px 20px;
            line-height: unset;
            font-size: 15px;
            margin-top: 24px;
        }
    }

    &__feedback{
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(86, 86, 86);
        background-color: rgba(86, 86, 86, 0.71);
        z-index: 999999;

        &__container{
            background-color: #fefefe;
            margin: 15% auto; /* 15% from the top and centered */
            padding: 23px 19px;
            border: 1px solid #888;
            width: 40%; /* Could be more or less, depending on screen size */
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            background: #26282C;
            border-radius: 10px;
            flex-direction: column;
    
            @include tablet{
                width: 90%;
                margin: 45% auto;
            }
    
            label{
                font-weight: 500;
                font-size: 16px;
                display: block;
                text-align: center;
                margin-bottom: 10px;
            }
    
            textarea{
                font-family: inherit;
                padding: 21px 18px;
                background-color: #000;
                font-weight: 500;
                font-size: 16px;
                line-height: 23px;
                width: 100%;
                border-radius: 10px;
                min-height: 155px;
                margin-top: 10px;
                border: none;
                color: #dcdcdc;

                @include tablet{
                    margin-top: 20px;
                }

                &::placeholder{
                    color: #dcdcdc;
                }
            }

            button{
                padding: 10px 25px;
                line-height: unset;
                font-size: 15px;
                margin-top: 24px;
            }
        }
    }

    &__freecourse{
        // display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 1; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(86, 86, 86);
        background-color: rgba(0, 0, 0, 0.63);
        z-index: 99999;
        font-family: $font-Jakarta;

        &__container{
            background-color: #fefefe;
            margin: 5% auto; /* 15% from the top and centered */
            padding: 20px 25px;
            border: 1px solid #888;
            width: 68%; /* Could be more or less, depending on screen size */
            display: flex;
            align-items: center;
            justify-content: center;
            row-gap: 4%;
            background: #000;
            border-radius: 10px;

            @include tablet{
                width: 95%;
                margin: 3% auto;
                flex-direction: column;
                padding: 20px 18px;
            }

            &--form{
                margin: 2% auto;
                padding: 22px 20px;
                width: 58%;

                @include tablet{
                    width: 95%;
                    padding: 22px 15px;
                }
            }

            &--drive{
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                width: 50%;
                padding: 27px 60px;

                @include tablet{
                    width: 95%;
                    padding: 27px 22px;
                }

                h1{
                    font-size: 17px;
                    font-weight: 400;
                    margin-top: 27px;
                }

                &__question{
                    h3{
                        font-size: 16px;
                        font-weight: 400;
                        margin-top: 44px;
                    }

                    &--container{
                        display: flex;
                        column-gap: 50px;
                        margin-top: 20px;

                        @include tablet{
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            row-gap: 30px;
                        }

                        &__active{
                            transform: scale(1.4);
                            p{
                                background-color: $color-main-blue;
                            }
                        }

                        div{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            @include tablet{
                                width: 38%;
                            }

                            p{
                                font-size: 14px;
                                border: 1px solid #FFFFFF;
                                border-radius: 10px;
                                padding: 4px 12px;
                            }

                            img{
                                height: 80px;
                                width: 80px;
                                object-fit: contain;
                            }
                        }

                    }
                }

                button{
                    margin-top: 40px;
                    border: none;
                    outline: none;
                    background-color: $color-main-blue;
                    font-size: 17px;
                    color: #fff;
                    padding: 12px 20px;
                    display: flex;
                    align-items: center;
                    border-radius: 8.90834px;
                    cursor: pointer;

                    img{
                        height: 14px;
                        margin-left: 10px;
                    }
                }
            }

            &--platform{
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                height: 90%;
                overflow-y: scroll;
                position: relative;

                @include tablet{
                    width: 95%;
                    margin: 5% auto;
                }

                &::-webkit-scrollbar-thumb {
                    background: #f1f1f1; 
                }

                &::-webkit-scrollbar {
                    width: 5px;
                  }
                   
                
                &__content{
                    margin-top: 18px;

                    h4{
                        font-size: 17px;
                        font-weight: 500;
                    }

                    p{
                        font-size: 14px;
                        font-weight: 200;
                        margin-top: 12px;
                        line-height: 28px;
                        display: flex;
                        align-items: center;

                        @include tablet{
                            display: block;
                        }

                        img{
                            height: 20px;
                            object-fit: contain;
                            margin:0 7px;

                            @include tablet{
                                vertical-align: middle;
                            }
                        }
                    }

                    &--nasscom{
                        height: 12px !important;
                        object-fit: contain;
                    }

                    &--sticky{
                        padding: 10px 0;
                        position: sticky;
                        bottom: -4%;
                        background-color: rgba(#000, .8);

                        button{
                            border: none;
                            outline: none;
                            background-color: $color-main-blue;
                            font-size: 15px;
                            font-weight: 600;
                            text-transform: capitalize;
                            color: #fff;
                            padding: 11px 24px;
                            display: flex;
                            align-items: center;
                            border-radius: 8.90834px;
                            cursor: pointer;
                        }
                    }
                }
            }

            &__img{
                width: 46%;
                display: flex;
                align-items: center;
                justify-content: center;

                @include tablet{
                    width: 100%;
                }

                img{
                    width: 80%;
                    object-fit: contain;
                }
            }

            &__border{
                width: 1.5%;
                min-height: 100%;
                align-self: stretch;
                border-left: 0.5px solid #BFBFBF;

                @include tablet{
                    display: none;
                }
            }

            &__txt{
                width: 47%;

                @include tablet{
                    width: 100%;
                }

                &--start{
                    font-size: 14px;
                    background-color: $color-quiz-percent-fill;
                    padding: 6px 10px;
                    display: inline-block;
                    border-radius: 5.0907px;
                    color: #000;
                    font-weight: 400;
                }

                h1{
                    font-weight: 400;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    @include tablet{
                        display: block;
                    }

                    img{
                        height: 30px;
                        margin-left: 10px;
                        vertical-align: middle;
                    }
                }
                
                h2{
                    font-size: 17px;
                    margin-top: 12px;
                    font-weight: 400;
                    span{
                        color: $color-quiz-percent-fill;
                        font-weight: 500;
                    }
                }

                h3{
                    margin-top: 23px;
                    font-size: 17px;
                    font-weight: 600;
                }

                &--points{
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    margin-top: 12px;
                    
                    p{
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 300;

                        img{
                            height: 30px;
                            object-fit: contain;
                            margin-right: 10px;
                        }
                    }
                }

                button{
                    margin-top: 55px;
                    background-color: $color-main-blue;
                    color: #fff;
                    padding: 12px 21px;
                    font-size: 17px;
                    outline: none;
                    border-radius: 8.42912px;
                    border: none;
                    font-weight: 500;
                    cursor: pointer;

                    @include tablet{
                        margin-top: 25px;
                    }
                }
            }

            &__input{
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;

                label{
                    font-weight: 400;
                    font-size: 16px;
                    margin-bottom: 7px;
                }

                input{
                    border: none;
                    outline: none;
                    font-size: 17px;
                    border-bottom: 0.890834px solid #848484;
                    background: transparent;
                    padding: 4px 8px;
                    padding-left: 0;
                    color: #fff;
                }

                textarea{
                    font-family: inherit;
                    padding: 21px 18px;
                    background-color: $color-main-back;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 23px;
                    width: 100%;
                    border-radius: 10px;
                    min-height: 30px;
                    margin-top: 4px;
                    border: none;
                    color: #dcdcdc;

                    @include tablet{
                        margin-top: 20px;
                    }

                    &::placeholder{
                        color: #dcdcdc;
                    }
                }

                &__options{
                    margin-bottom: 30px;

                    &--two{
                        flex-direction: row !important;
                        flex-wrap: wrap;
                        p{
                            width: 48%;
                            @include tablet{
                                width: 100%;
                            }
                        }
                    }

                    div{
                        margin-top: 17px;
                        display: flex;
                        flex-direction: column;
                        row-gap: 20px;
                        width: 100%;
    
                        p{
                            font-size: 15px;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
    
                            img{
                                height: 18px;
                                object-fit: contain;
                                margin-right: 9px;
                            }
                        }
                    }
                }

                &--btn{
                    border: none;
                    outline: none;
                    background-color: $color-main-blue;
                    font-size: 17px;
                    color: #fff;
                    padding: 12px 20px;
                    display: flex;
                    align-items: center;
                    border-radius: 8.90834px;
                    cursor: pointer;

                    img{
                        height: 14px;
                        margin-left: 10px;
                    }
                }
            }

            &__certificate{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                padding: 25px 30px;
                position: relative;

                @include tablet{
                    padding: 25px 0px;
                }

                &--close{
                    position: absolute;
                    right: 0%;
                    top: 0%;
                    height: 30px;
                    width: 30px;
                    object-fit: contain;
                    cursor: pointer;
                }

                &--share{
                    margin-left: auto;
                    p{
                        display: flex;
                        align-items: center;
                        column-gap: 10px;
                        font-size: 15px;
                        img{
                            height: 30px;
                            width: 30px;
                            object-fit: contain;

                            @include tablet{
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                }

                &__main{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;

                    img{
                        width: 60%;
                        border-radius: 10px;

                        @include tablet{
                            width: 100%;
                        }
                    }
                }

                &--add{
                    display: flex;
                    justify-content: space-between;
                    margin: 20px auto;
                    width: 60%;

                    @include tablet{
                        width: 100%;
                        flex-direction: column;
                        row-gap: 20px;
                        align-items: center;
                        justify-content: center;
                    }

                    a{
                        color: #fff;
                        text-decoration: none;
                        cursor: pointer;
                    }
                    p{
                        display: flex;
                        align-items: center;
                        font-size: 16px;

                        img{
                            height: 20px;
                            object-fit: contain;
                            margin-left: 10px;
                        }
                    }
                }
            }

        }
    }
}

.Toastify{
    z-index: 9999999;
}