.home{
    display: flex;
    margin-top: $margin-top-side;
    width: 100%;

    @include tablet{
        padding-left: 0;
    }

    &__main{
        width: 70%;
        overflow: hidden;
        
        @include tablet{
            width: 100%;
        }
        
        &__banner{
            width: 100%;
            height: 218px;
            background-size: contain;
            background-repeat: no-repeat;
            // padding: 31px 43px;

            @include tablet{
                min-height: 201px;
                // padding: 20px 17px;
                // display: flex;
                // flex-direction: column;
                // justify-content: flex-start;
                // background-size: cover;
            }
            
            h1{
                font-weight: 600;
                font-size: 30px;
                line-height: 40px;
                text-transform: capitalize;

                @include tablet{
                    font-size: 22px;
                    width: 50%;
                    line-height: 25px;
                }
            }

            button{
                display: inline-block;
                text-decoration: none;
                margin-top: 22px;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                background-color: $color-banner-btn;
                color: $color-txt-white;
                border: none;
                outline: none;
                padding: 10px 14px;
                border-radius: 5px;
                text-transform: capitalize;
                font-family: inherit;
                cursor: pointer;
                
                @include tablet{
                    width: fit-content;
                    margin-top: 17px;
                }
            }

            a{
                display: block;
                height: 100%;
                width: 100%;

                img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 4/1;
                }
            }
        }

        &__quiz{
            margin-top: 30px;

            h3{
                font-weight: 500;
                font-size: 21px;
                line-height: 25px;
            }

            &__container{
                margin-top: 30px;
                display: flex;

                @include tablet{
                    overflow-x: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }             
                }

                &__card{
                    width: 120px;
                    height: 111px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-main-back;
                    border-radius: 13px;
                    cursor: pointer;
                    
                    @include tablet{
                        min-width: 100px;
                        min-height: 100px;
                    }
                    
                    &:not(:last-child){
                        margin-right: 40px;

                        @include tablet{
                            margin-right: 20px;
                        }
                    }
                    img{
                        width: 80%;
                        object-fit: contain;
                    }
                }
            }
        }

        &__course{
            margin-top: 30px;

            h3{
                font-weight: 500;
                font-size: 20px;
            }

            &__container{
                margin: 14px 0;
                display: flex;
                justify-content: space-between;
                column-gap: 32px;
                overflow-x: scroll;
                box-shadow: inset -80px 0 40px -55px #1d212f;

                @include tablet{
                    overflow-x: scroll;
                }

                &::-webkit-scrollbar{
                    display: none;
                } 

                &__card{
                    min-width: 32.5%;
                    background-color: $color-main-back;
                    border: 0.5px solid #2A70FE;
                    border-radius: 10px;
                    cursor: pointer;
                    color: $color-white;
                    text-decoration: none;
                    overflow: hidden;

                    @include tablet{
                        min-width: 75%;
                    }

                    h4{
                        font-weight: 400;
                        font-size: 17px;
                        // line-height: 22px;
                        margin-bottom: 14px;
                        min-height: 60px;
                    }

                    &--txt{
                        padding: 8px 12px;
                    }

                    &--main{
                        width: 100%;
                        height: 158px;
                        object-fit: cover;
                    }

                    &--trainer{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 7px;

                        p{
                            display: flex;
                            align-items: center;
                            font-weight: 400;
                            font-size: 14.5px;
                            line-height: 18px;

                            img{
                                width: 35px;
                                height: 35px;
                                border-radius: 17.5px;
                                margin-right: 8px;
                            }
                        }

                        span{
                            display: flex;
                            align-items: center;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;

                            img{
                                margin-left: 3px;
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }

                    P{
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: 13.5px;
                        line-height: 18px;
                        margin: 9px 0;

                        img{
                            width: 18px;
                            height: 18px;
                            margin-right: 5px;
                        }
                    }

                    &--session{
                        width: fit-content;
                        margin: 17px 0;
                        border: 1px solid #239623;
                        border-radius: 20px;
                        padding: 5px 9px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        text-transform: capitalize;
                        img{
                            width: 8px;
                            height: 8px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        &__cont{
            margin-top: 30px;
            max-width: 100%;

            h3{
                font-weight: 500;
                font-size: 20px;
            }

            &__container{
                margin-top: 17px;
                display: flex;
                align-items: stretch;
                overflow-x: scroll;
                column-gap: 21px;
                max-width: 100%;

                &__card{
                    padding: 10px 8px;
                    padding-right: 16px;
                    background-color: $color-main-back;
                    display: flex;
                    // width: 35%;
                    min-width: 55%;
                    margin-right: 21px;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    color: $color-white;
                    text-decoration: none;
                    overflow: hidden;
                    height: 150px;

                    @include tablet{
                        min-width: 125%;
                    }

                    &:not(:first-child){
                        // &:hover{
                        //     .home__main__cont__container__card__img{
                        //         img{ display: none; }
                        //     }
                        // }
                    }


                    &__txt{
                        margin-left: 14px;
                        width: calc(100% - 153px);
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        h4{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            margin-bottom: 7px;
                        }

                        h5{
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 23px;
                            margin-bottom: 27px;
                        }

                        h6{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            margin-bottom: 7px;
                        }
                    }

                    &__img{
                        width: 153px;
                        position: relative;
                        overflow: hidden;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            
                            &:first-child{
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }

                    &--status{
                        height: 4px;
                        background: #D9D9D9;
                        border-radius: 5px;
                        width: 100%;
                        position: absolute;
                        bottom: 4%;

                        span{
                            border-radius: 5px;
                            display: block;
                            background-color: #2AFEED;
                            width: 0%;
                            height: 4px;
                        }
                    }
                }
            }
        }
    }

    &__right{
        width: 30%;
        padding: 0 26px;
        @include tablet{
            display: none;
        }
    }
    
    &__status{
        &__card{
            display: flex;
            padding: 11px 14px;
            background-color: $color-main-back;
            width: 100%;
            border-radius: 12px;
            &:not(:first-child){
                margin-top: 17px;
            }
            img{
                width: 40px;
                height: 40px;
                object-fit: contain;
                margin-right: 14px;
            }
            &__txt{
                width: 100%;
                h4{
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 20px;
                    display: flex;
                    justify-content: space-between;
                }
                &__status{
                    height: 8px;
                    background-color: $color-silver-home;
                    margin-top: 10px;
                    border-radius: 4px;
                    span{
                        display: block;
                        background-color: $color-main-blue;
                        height: 100%;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    &__update{
        margin-top: 40px;
        h4{
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
        }
        &__container{
            margin-top: 9px;
            &__card{
                background-color: $color-main-back;
                border-radius: 10px;
                border-left: 10px solid $color-main-blue;
                padding: 8px 12px;
                cursor: pointer;
                &:not(:first-child){
                    margin-top: 17px;
                }
                h4{
                    font-weight: 300;
                    font-size: 11px;
                    line-height: 16px;
                    margin: 7px 0;
                }
                h3{
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 26px;
                }
                p{
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    margin: 7px 0;
                }
            }
        }
    }
}