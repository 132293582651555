
@keyframes load {
    0% { width: 0; }
    50% { width: 50%; }
    100% { width: 100%; }
}

.loading{
    min-height: 100vh;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: $color-main-black;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    
    img{
        width: 154px;
        height: 90px;
        object-fit: contain;
    }
    &__bar{
        width: 284px;
        height: 6px;
        background-color: $color-loading-bc;
        border-radius: 5px;
        margin-top: 24px;
        span{
            display: block;
            animation: load 4s normal forwards;
            animation-iteration-count: infinite;
            height: 6px;
            border-radius: 5px;
            background-color: $color-main-blue;
        }
    }
}
