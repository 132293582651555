.courses{
    margin:32px 40px;
    background-color: $color-background-light-black;
    padding: 31px;
    width: 100%;

    @include tablet{
        margin:10px 20px 20px;
        background-color: $color-main-black;
        padding: 0px 0px;
    }

    h2{
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        padding-bottom: 30px;

        @include tablet{
            font-weight: 500;
            font-size: 25px;
            line-height: 35px;
            padding-bottom: 25px;
        }
    }

    &__container{
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
        
        @include tablet{
            background-color: $color-background-light-black;
            padding: 12px 10px;
            // overflow-x: scroll;
            column-gap: 25px;
        }       
    }
    ::-webkit-scrollbar-thumb{
        background: none;
    }
    ::-webkit-scrollbar-track{
        background: none;
    }

}
