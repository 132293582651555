.login{
    padding: 28px 40px;
    min-height: 100vh;
    @include tablet{
        padding: 28px 25px;
        min-height: 90vh;
    }
    
    &__container{
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;

        @include tablet{
            padding: 28px 25px;
        }
        h1{
            font-size: 20px;
            font-weight: 500;
            margin: 20px 0;
            text-align: center;
        }
   
        h3{
            font-size: 16px;
            font-weight: 400;
            font-size: 18px;
            margin: 16px 0;
        }

        form{
            @include tablet{
                width: 100%;
            }
        }
        
        &__input{
            display: flex;
            flex-direction: column;
            label{
                margin-top: 15px;
            }
            input{
                padding: 14px 16px;
                border: 1px solid rgba(42, 112, 254, 0.6);
                width: 364px;
                font-size: 15px;
                background: $color-main-black;
                border-radius: 8px;
                color: $color-txt-white;
                margin-top: 7px;
                font-family: $font-Jakarta;

                @include tablet{
                    width: 100%;
                }         
            }

            input::placeholder{
                color: #C7C7C7;
            }
        }
        
        button{
            width: 364px;
            background-color: $color-main-blue;
            color: $color-txt-white;
            padding: 11px 0;
            text-align: center;
            border-radius: 8px;
            margin-top: 30px;
            font-weight: 500;
            font-size: 17px;
            font-family: $font-Jakarta;
            cursor: pointer;
            @include tablet{
                width: 100%;
            }        
        }
    }
}