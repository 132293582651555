.react-joyride__tooltip button{
    color: #2A70FE !important;
}

.react-joyride__tooltip {
    width: 348px!important;
    padding: 18px!important;
    text-align: left;
}

.react-joyride__tooltip div{
    padding: 0 !important;
}

.react-joyride__spotlight{
    border: 3px solid #2A70FE !important;
    border-radius: 5px !important;
}

.guide__modal{
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(86, 86, 86);
    background-color: rgba(86, 86, 86, 0.71);
    z-index: 999;

    &__container{
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 23px 19px;
        border: 1px solid #888;
        width: 30%; /* Could be more or less, depending on screen size */
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background: #26282C;
        border-radius: 10px;
        flex-direction: column;

        @include tablet{
            width: 90%;
            margin: 45% auto;
        }

        h2{
            text-align: center;
            font-size: 20px;
            font-weight: 500;
        }

        &__btns{
            width: 100%;
            display: flex;
            justify-content: center;
            column-gap: 20px;
            margin-top: 30px;

            button{
                border: none;
                outline: none;
                color: #fff;
                font-size: 16px;
                cursor: pointer;
            }

            button:first-child{
                background-color: transparent;
            }

            button:last-child{
                background-color: #2A70FE;
                padding: 10px 15px;
                border-radius: 5px;
            }
        }
    }
}