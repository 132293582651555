.courseVideo{
    padding: 16px 10px;
    // margin-top: 10px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    @include tablet{
        flex-direction: column;
        padding: 0;
        width: 100%;
    }

    &--active{
        color: $color-main-blue;
        border-bottom: 2px solid $color-main-blue;
        z-index: 2;
    }

    &__player{
        width: 68%;

        h1 {
            padding-top: 8px;
            font-size: 20px;
            font-weight: 500;
            min-height: 66px;
            margin-bottom: 15px;
            // margin-bottom: 29px;

            @include tablet{
                min-height: unset;
            }
        }

        &--full{
            width: 100%;
            padding: 0 80px;
            
            @include tablet{
                padding: 0;
            }
        }

        @include tablet{
            width: 100%;
        }

        .plyr{
            width: 100% !important;
            border: 1px solid $color-border-silver;

            @include tablet{
                height: 340px;
            }
        }

        &__features{
            margin-top: 31px;
            margin-bottom: 30px;
            
            &--title{
                display: flex;
                width: 100%;
                column-gap: 80px;
                border-bottom: 0.4px solid #828282;

                @include tablet{
                    column-gap: 21px;
                    width: 100%;

                    @include tablet{
                        overflow-x: scroll;
                    }
                }

                h1{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    padding-bottom: 13.48px;
                    cursor: pointer;
                    margin-bottom: 0;
                    min-height: unset;

                    @include tablet{
                        min-width: fit-content;
                    }
                }
            }

            &--notes{
                margin: 30px 0;

                &__rte{
                    padding: 17px 18px;
                    background-color: $color-main-back;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    cursor: pointer;

                    &--btns{
                        display: flex;
                        margin-top: 30px;
                        margin-bottom: 41px;
                        button{
                            margin-top: 0;
                            padding: 8px 43px;
                            margin-right: 21px;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 23px;
                            border-radius: 8px;
                            &:last-child{
                                background-color: transparent;
                                color: $color-main-blue;
                                border: 1px solid $color-main-blue;
                            }
                        }
                    }

                }

                &__container{
                    &__card{
                        padding: 19px 16px;
                        border: 1px solid $color-border-notes;
                        border-radius: 10px;
                        margin-top: 34px;

                        h3{
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }

                        h5{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 30px;
                            margin-bottom: 15px;
                            display: flex;
                            align-items: center;

                            span{
                                background-color: $color-background-blue;
                                padding: 6px 10px;
                                display: inline-block;
                                margin-right: 14px;
                                border-radius: 5px;
                                cursor: pointer;
                            }
                        }
                        
                        p{
                            padding: 10px 6px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            background-color: $color-main-back;

                            h1,h2,h3{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &--resource{
                margin-top: 31px;
                width: 100%;

                &__btns{
                    display: flex;
                    column-gap: 30px;
                    
                    @include tablet{
                        flex-wrap: wrap;
                        column-gap: 21px;
                        row-gap: 23px;
                    }

                    button{
                        padding: 9px 17px;
                        width: fit-content;
                        border: none;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 23px;
                        border-radius:  5px;
                        background: transparent;
                        color: $color-white;
                        border: 1px solid $color-white;
                        cursor: pointer;
                    }
                }

                &--active{
                    background-color: $color-white !important;
                    color: $color-main-black !important;
                }

                &__container{
                    margin-top: 36px;

                    span{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        column-gap: 36px;
                        row-gap: 49px;

                        @include tablet{
                            column-gap: 12px;
                        }
                    }
                    
                    &__study{
                        width: 230px;
                        min-height: 327px;
                        padding: 13px 10px;
                        background-color: $color-main-back;
                        box-shadow: 5px 5px 0px #145EEE;
                        border-radius: 10px;
                        cursor: pointer;
                        color: #fff;
                        text-decoration: none;

                        @include tablet{
                            width: 80%;
                            padding: 10px 14px;
                            min-height: unset;
                        }

                        h5{
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            margin-bottom: 14px;
                            padding: 0 10px;

                            @include tablet{
                                font-size: 16px;
                            }
                        }

                        img{
                            width: 100%;
                            height: 223px;
                        }

                        p{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: right;
                            margin-top: 9px;
                        }
                    }

                    &__upload{
                        width: 193px;
                        min-height: 222px;
                        padding: 15px 16px;
                        background-color: $color-main-back;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-radius: 10px;
                        cursor: pointer;
                        object-fit: contain;
                        word-wrap: break-word;

                        @include tablet{
                            width: 48%;
                        }
                        
                        img{
                            width: 70%;
                        }

                        h6{
                            font-weight: 400;
                            font-size: 15px;
                            margin-top: 9px;
                            margin-bottom: 6px;
                            text-align: center;
                            width: 100%;
                        }
                        
                        p{
                            text-align: center;
                            display: flex;
                            align-items: center;
                            margin-top: auto;

                            font-weight: 400;
                            font-size: 12px;
                            line-height: 24px;

                            img{
                                width: 17.46px;
                                height: 14.3px;
                                margin-right: 5px;
                                object-fit: contain;
                            }
                            
                            &:last-child{
                                justify-self: flex-end;
                                margin-top: 39px;
                            }
                        }
                    }
                }                
            }

            &--feedback{
                margin-top: 30px;

                h4{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                }

                textarea{
                    font-family: inherit;
                    padding: 21px 18px;
                    background-color: $color-main-back;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    width: 100%;
                    border-radius: 20px;
                    min-height: 155px;
                    margin-top: 46px;
                    border: none;
                    color: #dcdcdc;

                    @include tablet{
                        margin-top: 20px;
                    }

                    &::placeholder{
                        color: #dcdcdc;
                    }
                }

                button{
                    padding: 12px 19px;
                    line-height: unset;
                    font-size: 16px;
                }
            }
        }
    }

    &__stats{
        width: 28%;
        margin-right: 20px;
        
        @include tablet{
            width: 100%;
            display: none;
            margin-top: 35px;
        }

        &__progress{
            padding: 8px;
            padding-left: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 12px;
            border: 1px solid #5E6779;
            background: linear-gradient(0deg, rgba(27, 29, 33, 0.10) -1110.47%, rgba(28, 37, 55, 0.10) -690.39%, rgba(30, 46, 79, 0.10) -403.17%, rgba(33, 61, 119, 0.10) -185.29%, rgba(36, 79, 167, 0.10) -36.73%, rgba(42, 112, 254, 0.10) 100%), #18181A;
            margin-bottom: 15px;
            position: relative;
            overflow: hidden;

            @include tablet{
                margin-bottom: 40px;
            }

            &__fill{
                background: linear-gradient(90deg, #493603 0%, #7F5C00 100%);
                background-size: 100% 100%; 
                position: absolute;
                left: 0;
                width: 0%;
                height: 100%;
                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -100%;
                    width: 80px;
                    height: 100%;
                    background: #F9C846;
                    filter: blur(15px);
                    animation: shine 5s infinite linear;
                }
            }

            h4{
                font-weight: 400;
                font-size: 14px;
                z-index: 1;
                background-color: transparent;
            }
            
            img{
                z-index: 1;
                height: 48px;
                object-fit: contain;
            }
        }
        
        &__content{
            border-radius: 18px;
            position: relative;
            border: 1px solid $color-border-silver;
           
            &--scroll{
                overflow-y: scroll;
                height: 100vh;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 5px;
                }
    
                &::-webkit-scrollbar-track{
                    background: rgba(255, 255, 255, 0.1);
                }
    
                &::-webkit-scrollbar-thumb{
                    background: rgba($color-scroll-bar-thumb, 0.5);
                    border-radius:4px;
                }
            }

            h3{
                background: $background-gradient;
                padding: 16px 24px;
                font-size: 14px;
                font-weight: 400;
                border-radius: 18px 18px 0 0;
                position: sticky;
                top: 0;
                left: 0;
                width: 100%;
                border-bottom: 1px solid $color-border-silver;;

                @include tablet{
                    display: none;
                }
            }

            &--card{
                padding: 8px;
                margin-bottom: 10px;
                // opacity: 0.5;

                &--active{
                    opacity: 1;
                }

                button{
                    padding: 10px 18px;
                    color: #fff;
                    background-color: $color-main-blue;
                    outline: none;
                    border: none;
                    border-radius: 10px;
                    font-size: 15px;
                    cursor: pointer;
                }

                h4{
                    font-weight: 400;
                    font-size: 14px;
                    padding: 16px;
                    border-bottom: 1px solid $color-silver-border;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    width: 100%;

                    img{
                        width: 12px;
                        height: 13px;
                        object-fit: contain;
                    }
                }

                &--content{
                    display: flex;
                    align-items: flex-start;
                    padding: 18px;

                    cursor: pointer;

                    // &:not(:last-child){
                    //     border-bottom: 1px solid $color-border-silver;
                    // }

                    img{
                        width: 18px;
                        height: 18px;
                        object-fit: contain;
                        margin-right: 19px;
                    }

                    h5{
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;
                        align-items: center;

                        img{
                            width: 24px;
                            height: 24px;
                            object-fit: contain;
                            margin-right: 0;
                        }
                    }

                    h6{
                        color: $color-silver;
                        font-size: 14px;
                        font-weight: 400;
                        margin-top: 4px;
                        margin-left: 24px;
                    }

                    &--active{
                        h6{
                            color: $color-main-blue;
                        }
                    }

                    &--quiz{
                        background-color: $color-back-silver;
                        border-radius: 12px;

                        &--active{
                            border: 1px solid $color-main-blue;
                        }

                        &--assessment{
                            margin-top: 10px;
                            background-color: $color-blue-bc;
                        }
                        
                        &--container{
                            display: flex;
                            column-gap: 12px;

                            div{
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                                flex-direction: column;
                            }

                            img {
                                width: 48px;
                                height: 48px;
                                object-fit: contain;
                                margin-right: 0;
                            }

                            p{
                                color: $color-txt-silver;
                                font-size: 12px;
                                font-weight: 400;
                            }

                            h5{
                                font-size: 14px;
                                font-weight: 500;
                                margin-left: 0;
                            }
                        }

                    }

                    &--certificate{
                        &--main{
                            width: 100% !important;
                            height: unset !important;
                            object-fit: contain !important;
                            margin-right: 0 !important;
                            margin-top: 18px !important;
                        }
                        div{
                            margin-top: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            a{
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                color: #fff;
                                text-decoration: none;
                                img{
                                    height: 20px;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }

                    &--play{
                        margin-top: 10px;
                        font-weight: 400;
                        font-size: 15px;

                        img{
                            width: 18px;
                            height: 18px;
                            object-fit: contain;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }

    &__assignment{
        display: flex;
        flex-direction: column;
        row-gap: 31px;

        &__no{
            font-size: 17px;
            font-weight: 400;

        }
        
        &__card{
            background: #26282C;
            border-radius: 10px;
            width: 100%;
            padding: 23px 16px;
            cursor: pointer;

            @include tablet{
                padding: 10px 15px;
            }

            &__container{
                display: flex;
                align-items: center;
                column-gap: 18px;

                @include tablet{
                    column-gap: 25px;
                    flex-wrap: wrap;
                    row-gap: 20px;
                    overflow: hidden;
                }
            }

            &__trainer{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 10%;

                @include tablet{
                    width: 20%;
                }

                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 55px;
                    object-fit: contain;

                    @include tablet{
                        width: 20px;
                        height: 20px;
                    }
                }
                h6{
                    text-align: center;
                    margin-top: 7px;
                }
            }

            &__content{
                width: 22%;

                @include tablet{
                    width: 35%;
                }

                h4{
                    font-size: 17px;
                    font-weight: 500;
                }
                h5{
                    font-weight: 400;
                    font-size: 14px;
                    text-transform: capitalize;
                    margin-top: 15px;
                    word-break: break-all;
                }
            }

            &__deadline{
                width: 17%;

                @include tablet{
                    width: 25%;
                }
            }

            &__status{
                width: 17%;

                @include tablet{
                    width: 35%;
                }

                h4,h5,h6{
                    width: 100%;
                    font-weight: 600;
                    font-size: 15px;
                    border-radius: 5px;
                    padding: 7px 6px;
                    text-align: center;
                }

                h6{
                    background-color: $color-Inprogress;
                }

                h4{
                    background-color: $color-Approve;
                }

                h5{
                    background-color: $color-Assign;
                }

            }

            &__percentage{
                width: 19%;
                div{
                    height: 4px;
                    width: 100%;
                    border-radius: 5px;
                    background-color: $color-percentage-background;
                    overflow: hidden;

                    span{
                        border-radius: inherit;
                        height: 4px;
                        width: 0%;
                        display: block;
                        background-color: $color-main-blue;
                    }
                }
                h5{
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 12px;
                }
            }

            &__arr{
                margin-left: auto;
                img{
                    width: 17px;
                    height: 8px;
                    object-fit: contain;
                }
            }

            &__active{
                margin-top: 31px;
                padding-left: calc(10% + 18px);
                
                h4{
                    font-size: 18px;
                    font-weight: 500;
                }

                &__assets{
                    p{
                        background-color: rgba(255, 255, 255, 0.3);
                        padding: 8px 18px;
                        width: fit-content;
                        border-radius: 10px;
                        margin-top: 12px;
                        cursor: pointer;

                        @include tablet{
                            word-break: break-all;
                        }
                    }
                }

                &__submission{
                    margin-top: 30px;
                    p{
                        background-color: rgba(255, 255, 255, 0.3);
                        padding: 8px 18px;
                        width: fit-content;
                        border-radius: 10px;
                        margin-top: 12px;
                        cursor: pointer;
                        word-break: break-all;
                    }
                }

                &__upload{
                    margin-top: 5px;
                    
                    input{
                        display: none;
                    }

                    button{
                        line-height: unset;
                        font-size: 16px;
                        margin: 15px 0;
                        margin-bottom: 7px;
                    }

                    &__file{
                        background-color: transparent;
                        border: 1px solid $color-main-blue;
                        margin-right: 18px !important;
                    }

                    p{
                        font-size: 15px;
                        font-weight: 600;
                        color: $color-main-blue;
                    }
                }

                &__comment{
                    margin-top: 15px;
                    p{
                        border: 2px solid #1B5BD9;
                        border-radius: 5px;
                        min-height: 60px;
                        margin-top: 20px;
                        width: 95%;
                        padding: 5px 10px;
                        
                        ul{
                            font-weight: 400;
                            font-size: 16px;
                            list-style: inside;
                            span{
                                font-size: 12px;
                                color: rgba(255, 255, 255, 0.3);
                            }
                        }
                    }
                }

            }

        }
    }
}

.course__quiz{
    border: 1px solid $color-border-silver;
    background-color: $color-main-black;
    width: 100%;
    background-image: url('../assets/quiz/quiz-bc.png');
    background-size: 62%;
    background-repeat: no-repeat;
    background-position: top;
    object-fit: contain;
    padding: 41px 80px;
    min-height: 470px;
    border-radius: 16px;

    @include tablet{
        padding: 12px;
    }

    &--assessment{
        background-color: $color-assessment-bc;
        background-image: none;
        padding: 41px 30px;

        @include tablet{
            padding: 20px;
        }
    }
    
    &__assessment{
        display: flex;
        column-gap: 28px;

        @include tablet{
            flex-direction: column;
        }

        &__start{
            width: 40%;

            @include tablet{
                width: 100%;
            }

            h1{
                font-size: 36px;
                font-weight: 500;
            }

            h4{
                color: #B3BED4;
                font-size: 16px;
                font-weight: 400;
            }

            svg{
                margin-top: 83px;
                width: 18.012px;
                height: 18.012px;

                @include tablet{
                    margin-top: 20px;
                }
            }

            p{
                background-color: $color-white;
                color: #000;
                padding: 12px;
                border-radius: 4px;
                font-size: 15px;

                span{
                    color: $color-main-blue;
                    display: block;
                }
            }

            &__btn{
                margin-top: 24px;
                padding: 14px 32px;
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                background-color: $color-main-blue;
                outline: none;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        
        &__questions{
            width: 60%;

            @include tablet{
                width: 100%;
                margin-top: 30px;
            }

            div{
                display: flex;
                padding: 12px 24px;
                align-items: center;
                gap: 10px;
                align-self: stretch;
                border-radius: 12px;
                border: 1px solid #36424C;
                margin: 12px 0;

                p{
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }

    &__start{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img{
            width: 72px;
            height: 26.464px;
            object-fit: contain;
            margin-bottom: 81px;
        }

        h1{
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 8px;
        }

        p{
            text-align: center;
            color: $color-txt-blue;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 32px;
        }

        button{
            padding: 8px 32px;
            outline: none;
            border: none;
            background-color: $color-main-blue;
            border-radius: 5px;
            color: $color-white;
            text-align: center;
            font-family: inherit;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
        }
        
    }

    &__question{
        h4{
            color: $color-main-blue;
            font-size: 12px;
            font-weight: 500;
            margin-top: 65px;

            @include tablet{
                margin-top: 20px;
            }
        }

        h5{
            font-size: 24px;
            font-weight: 500;
            margin-top: 8px;
            margin-bottom: 16px;
        }

        &__options{
            padding: 16px;
            border-radius: 8px;
            border: 1px solid #323D53;
            background: linear-gradient(0deg, rgba(27, 29, 33, 0.10) -1110.47%, rgba(28, 37, 55, 0.10) -690.39%, rgba(30, 46, 79, 0.10) -403.17%, rgba(33, 61, 119, 0.10) -185.29%, rgba(36, 79, 167, 0.10) -36.73%, rgba(42, 112, 254, 0.10) 100%), #18181A;
            gap: 10px;
            font-size: 16px;
            font-weight: 400;
            margin: 8px 0;
            cursor: pointer;

            &__select{
                border: 1px solid $color-white;
            }

            &__correct{
                border: 1px solid #239623;
            }

            &__wrong{
                border: 1px solid #E40000
            }
        }

        button{
            padding: 8px 32px;
            outline: none;
            border: none;
            background-color: $color-main-blue;
            border-radius: 5px;
            color: $color-white;
            text-align: center;
            font-family: inherit;
            font-size: 15px;
            font-weight: 500;
            cursor: pointer;
            margin-top: 16px;
        }
    }

    &__end{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 400px;
        width: 100%;

        h1{
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 8px;
        }

        p{
            text-align: center;
            color: $color-txt-blue;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 32px;
        }

        &__btn{
            display: flex;
            column-gap: 20px;
        }

        button{
            padding: 8px 32px;
            outline: none;
            border: none;
            background-color: transparent;
            border: 1px solid $color-main-blue;
            &:not(:first-child){
                background-color: $color-main-blue;
            }
            border-radius: 5px;
            color: $color-white;
            text-align: center;
            font-family: inherit;
            font-size: 18px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}