.sidebar{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: fit-content;
    padding: 12px 12px;
    padding-top: 0;
    background-color: $color-black-background;
    z-index: 888;
    row-gap: 37px;
    transition: all 0.5s ease;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 99999;

    @include tablet{
        display: none !important;
    }

    a{
        color: $color-white;
        text-decoration: none;
    }
    

    &__menu{
        padding: 12px 15px;
        border-bottom: 1px solid #3C3C3C;

        img{
            height: 44px;
            width: 30px;
            object-fit: contain;
        }
    }

    &__card{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        cursor: pointer;

        @include tablet{
            padding-left: 16px;
        }

        &:nth-child(2){
            // margin-top: $margin-top-side;
            margin-top: 0px;
        }

        svg{
            height: 25px;
            width: 25px;
        }

        img{
            width: 23px;
            height: 24px;
            object-fit: contain;
            position: relative;

            @include tablet{
                width: 18px;
                height: 18px;
            }
        }

        p{
            margin-top: 10px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: inherit;

            @include tablet{
                font-size: 15px;
            }
        }

        &--active{
        
        }
        
    }

    &__logout{
        display: none;
        position: absolute;
        bottom: 20%;
        left: 16px;
        padding: 10px 12px;
        font-size: 18px;
        border: none;
        outline: none;
        background-color: orangered;
        color: #fff;

        @include tablet{
            display: block;
        }
    }

    &__footer{
        display: none;
        position: fixed;
        bottom: 0%;
        width: 100%;
        background-color: $color-black-background;
        z-index: 99999;
        padding: 12px 0;
        justify-content: space-around;
        column-gap: 8px;

        a{
            text-decoration: none;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 19%;
            height: 50px;

            svg{
                height: 26px;
                width: 26px;
                object-fit: cover;
            }

            p{
                font-size: 10px;
                margin-top: 10px;
                font-weight: 600;
                text-align: center;
            }
        }

        @include tablet{
            display: flex;
        }

       
    }
}

@media screen and (orientation: portrait) {
    .sidebar__footer{
        display: flex;
    }
}

@media screen and (orientation: landscape) {
    .sidebar__footer{
        display: none !important;
    }
}