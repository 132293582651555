nav{
    padding: 12px 40px;
    position: sticky;
    left: 0;
    top: 0;
    background-color: $color-main-black;
    width: 100%;
    z-index: 999;
    border-bottom: 1px solid #3C3C3C;
    
    @include tablet{
        padding: 26px 16px;
    }

    display: flex;
    align-items: center;

    a{
        text-decoration: none;
        color: $color-white;
    }

    .nav{
        &__logo{
            margin-left: 7%;
            @include tablet{
                margin-left: 0;
            }

            img{
                width: auto;
                height: 44px;

                @include tablet{
                    height: 35px;
                }
            }
        }
        &__sidebar{
            margin-left: 20px;
            cursor: pointer;
            img{
                width: 34px;
                height: 34px;
            }
        }
        &__home{
            margin-left: auto;
            display: flex;
            column-gap: 30px;

            // @include tablet{
            //     display: none;
            // }

            &__noti{
                position: relative;
                font-size: 10px;
                font-weight: 500;
                cursor: pointer;

                @include tablet{
                    display: none;
                }

                span{
                    position: absolute;
                    right: 14%;
                    top: 0;
                }

                img{
                    width: 28px;
                    height: 32px;
                }

                &--pop{
                    position: absolute;
                    right: 0;
                    // bottom: 0%;
                    width: 350px;
                    padding: 25px 20px;
                    background-color: $color-main-back;
                    border-radius: 21px;
                    border: 0.5px solid $color-blue-dark;

                    h2{
                        font-size: 16px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        img{
                            height: 18px;
                            object-fit: contain;
                        }
                    }

                    h5{
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                        font-size: 16px;
                        border-bottom: 1px solid #CECECE;
                        font-weight: 400;
                        padding-bottom: 5px;
                    }

                    &__container{
                        max-height: 428px;
                        overflow-y: scroll;
                        padding-right: 10px;

                        &--empty{
                            text-align: center;
                            margin-top: 15px;
                            font-weight: 400;
                            font-size: 14px;
                        }

                        &::-webkit-scrollbar {
                            -webkit-appearance: none;
                            width: 5px;
                        }
            
                        &::-webkit-scrollbar-track{
                            background: rgba(255, 255, 255, 0.1);
                        }
            
                        &::-webkit-scrollbar-thumb{
                            background: rgba($color-scroll-bar-thumb, 0.5);
                            border-radius:4px;
                        }

                        &__card{
                            padding: 11px 12px;
                            background: #313234;
                            margin-top: 24px;
                            border-radius: 10px;

                            &--trainer{
                                display: flex;
                                
                                img:first-child{
                                    width: 36px;
                                    height: 36px;
                                }

                                div{
                                    margin-left: 12px;
                                    h4{
                                        font-weight: 600;
                                        font-size: 14px;
                                    }
                                    p{
                                        font-weight: 400;
                                        font-size: 12px;
                                        margin: 0;
                                    }
                                }
                            }

                            &--main{
                                font-weight: 400;
                                font-size: 12px;
                                margin-left: 48px;
                                height: 38px;
                                overflow: hidden;
                                border-top: 0.5px solid #828282;
                                padding-top: 5px;
                            }
                        }
                    }

                }
            }

            &__user{
                display: flex;
                align-items: center;
                position: relative;
                
                p{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    margin-left: 9px;
                }
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 17.5px;
                }

                ul{
                    display: none;
                    position: absolute;
                    background: $color-main-back;
                    border: 1px solid $color-dark-grey;
                    border-radius: 5px;
                    top: 48px;
                    right: 0;
                    z-index: 10;
                    list-style-type: none;
                    width: 265px;

                    @include tablet{
                        width: 205px;
                        top: 50px;
                    }

                    li{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        color: $color-nav;
                        padding: 16px 20px;

                        &:first-child{
                            img{
                                width: 13px;
                                height: 15px;
                            }
                        }

                        &:last-child{
                            margin-bottom: 0;
                            img{
                                width: 17px;
                                height: 19px;
                            }
                        }
                        
                        a{
                            text-decoration: none;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;
                        }

                        &:hover{
                            background-color: $color-grey;
                        }
                    }
                }

                &:hover{
                    ul{
                        display: block;
                    }
                    .nav__home__user--options{
                        display: block;
                    }
                }


                &--options{
                    position: absolute;
                    left: -140%;
                    bottom: -90%;
                    background-color: #fff;
                    padding: 12px 10px;
                    border-radius: 5px;
                    display: none;

                    @include tablet{
                        bottom: -150%;
                    }
                    
                    button{
                        font-size: 16px;
                        padding: 5px 15px;
                        border: none;
                        outline: none;
                        color: #fff;
                        background-color: orangered;
                        cursor: pointer;
                    }
                }

            }

            &__card{
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                background-color: $color-main-back;
                padding: 12px 16px;
                border-radius: 10px;

                @include tablet{
                    display: none;
                }

                img{
                    width: 21px;
                    height: 19px;
                    margin-right: 8px;
                }

            }
        }
    }
}

.nav__top{
    display: block;
    background-color: #2250f4;
    padding: 10px;
    font-weight: 600;
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    text-align: center;

    span{
        text-decoration: underline;
    }
}
