@keyframes slideDown {
    0% {
        transform: translateY(100%);
        opacity: 0;
      }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  }

.quiz{
    background-color: $color-main-black;
    width: 100%;
    min-height: 90vh;
    background-image: url('../assets/quiz/quiz-bc.png');
    background-size: 62%;
    background-repeat: no-repeat;
    background-position: top;
    object-fit: contain;
    padding: 41px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo{
        width: 128px;
        height: 56px;
        object-fit: contain;
        margin-bottom: 45px;
    }

    h1{
        text-align: center;
        font-weight: 500;
        font-size: 39px;
        width: 60%;
        margin-bottom: 90px;

        @include tablet{
            width: 98%;
            font-size: 28px;
            margin-bottom: 30px;
        }
    }

    h4{
        font-weight: 400;
        font-size: 18px;
        margin-top: 5px;

        @include tablet{
            text-align: center;
        }
    }

    &__container{
        margin-top: 107px;
        display: flex;
        justify-content: center;
        column-gap: 3%;
        width: 100%;

        @include tablet{
            flex-direction: column;
            align-items: center;
            margin-top: 70px;
        }

        &__card{
            background-size: 100% 100%;
            object-fit: contain;
            padding: 15px 30px;
            width: 28%;
            cursor: pointer;

            @include tablet{
                width: 95%;
                padding: 18px 25px;
            }

            h4{
                font-weight: 400;
                font-size: 20px;
                text-align: left;
            }

            &__status{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-top: 19px;
                margin-bottom: 11px;

                p{
                    width: 80%;
                    height: 5px;
                    background-color: $color-quiz-percent-bc;
                    border-radius: 10px;
                    overflow: hidden;
                    span{
                        display: block;
                        width: 0%;
                        height: 5px;
                        background-color: $color-quiz-percent-fill;
                        border-radius: inherit;
                    }
                }

                h5{
                    font-weight: 500;
                    font-size: 17px;
                }
            }

            p{
                font-weight: 500;
                font-size: 14px;
            }

            a{
                display: block;
                width: fit-content;
                padding: 12px 20px;
                background: $color-main-blue;
                border-radius: 5px;
                margin-top: 29px;
                text-decoration: none;
                color: #fff;
            }
        }
    }

    &__play{
        padding: 0 88px;
        width: 100%;
        margin-top: 5%;
        display: flex;
        justify-content: space-between;

        @include tablet{
            flex-direction: column-reverse;
            padding: 0 10px;
        }

        &__content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70%;

            @include tablet{
                width: 100%;
                margin-top: 40px;
            }

            &__animate{
                animation: slideDown 1s forwards;
                opacity: 0;
            }

            

            h3{
                font-weight: 600;
                font-size: 20px;
                text-align: center;
                width: 80%;
                margin: 0 auto;
                margin-bottom: 55px;
                user-select: none;
                line-height: 35px;

                @include tablet{
                    margin-bottom: 29px;
                    width: 95%;
                }
            }

            &__options{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                row-gap: 31px;
                column-gap: 31px;
                width: 100%;

                @include tablet{
                    flex-direction: column;
                    row-gap: 20px;
                }

                p{
                    z-index: 1;
                    width: 40%;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: center;
                    padding: 19px 25px;
                    background-image: url('../assets/quiz/option-bc.png');
                    background-size: 100% 100%;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include tablet{
                        width: 100%;
                    }
                }
            }
        }

        &__giphy{
            width: 38%;
            min-height: 320px;
            position: relative;
            border:1px solid $color-main-blue;
            border-radius: 20px;

            @include tablet{
                width: 100%;
                min-height: 210px;
            }

            iframe{
                width: 100%;
            }
        }

        &__next{
            margin-top: 40px;
            @include tablet{
                margin-top: 50px;
            }

            img{
                cursor: pointer;
                height: 60px;
                width: 60px;
                object-fit: contain;
            }
        }
    }

    &__end{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;

        @include tablet{
            flex-direction: column-reverse;
        }

        &__txt{
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include tablet{
                width: 100%;
                margin-top: 40px;
            }            

            h1{
                width: 100%;
                font-size: 32px;
                font-weight: 700;
            }

            &--circle{
                width: 100%;
            }

            p{
                margin-top: 35px;
                font-weight: 400;
                font-size: 18px;
            }

            &__btn{
                display: flex;
                margin-top: 30px;
                justify-content: center;
                column-gap: 30px;
                width: 100%;

                @include tablet{
                    flex-direction: column;
                    align-items: center;
                    row-gap: 20px;
                }

                a{
                    text-align: center;
                    width: 40%;
                    margin-top: 42px;
                    font-size: 17px;
                    padding: 14px 35px;
                    color: #fff;
                    text-decoration: none;
                    border: 2px solid $color-main-blue;
                    border-radius: 30px;

                    @include tablet{
                        width: 90%;
                        margin-top: 0;
                    }
                }
            }

        }

        &__media{
            min-height: 320px;
            position: relative;
            border:1px solid $color-main-blue;
            border-radius: 20px;
            width: 38%;

            @include tablet{
                width: 100%;
                min-height: 210px;
            } 

            iframe{
                width: 100%;
                height: 100%;
            }
        }
    }
}