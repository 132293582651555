*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body{
    box-sizing: border-box;
    background: $color-main-black;
    color: $color-txt-white;
    font-family: $font-Poppins;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: .8rem;    /* for vertical scrollbars */
    height: .5rem;    /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius:4px;
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius:4px;
}

.displayNone{
    display: none !important;
}

.container{
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
}
.content{
    display: flex;
    align-items: stretch;
    width: 89%;
    margin-left: 10%;
    min-height: 87vh;

    @include tablet{
        padding: 2px 18px;
        min-height: unset;
        width: 100%;
        margin-left: 0;
    }
}

:root{
    --plyr-color-main: #2A70FE;
    --plyr-video-background: rgb(38, 40, 44, 1);
}

.plyr{
    border-radius: 20px;
    font-family: $font-Jakarta;
    width: 100%;
}

.Toastify__toast{
    z-index: 999999;
}