.onboard{
    padding: 30px 80px;
    @include tablet{
        padding: 0px 19px;
        padding-bottom: 10px;
    }
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include tablet{
            flex-direction: column-reverse;
        }
        &__txt{
            width: 47%;
            @include tablet{
                width: 100%; 
            }
            h1{
                font-weight: 500;
                font-size: 25px;
                margin: 30px 0;
                @include tablet{
                    font-size: 22px;
                }
            }
            h3{
                font-weight: 500;
                font-size: 20px;
            }

            input{
                padding: 14px 16px;
                border: 1px solid rgba(42, 112, 254, 0.6);
                width: 364px;
                font-size: 15px;
                background: $color-main-black;
                border-radius: 8px;
                color: $color-txt-white;
                margin-top: 7px;
                font-family: $font-Jakarta;
                margin-top: 20px;

                @include tablet{
                    width: 100%;
                }
            }

            &__options{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin: 20px 0;
                div{
                    cursor: pointer;
                    padding: 8px 29px;
                    background: $color-main-back;
                    border-radius: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    font-weight: 400;
                    font-size: 16px;
                    margin-right: 15px;
                    margin-top: 22px;
                    line-height: 40px;
                    @include tablet{
                        font-size: 14.5px;
                        padding: 11px;
                    }
                }

                &--check{
                    text-align: left;
                    font-size: 14px;
                    vertical-align: middle;
                    margin-top: 20px;

                    input{
                        width: fit-content;
                        margin-right: 5px;
                    }

                    a{
                        color: #fff;
                    }
                }
            }
            button{
                padding: 8px 56px;
                font-size: 18px;
                @include tablet{
                    line-height: 40px;
                    padding: 3px 0;
                }
            }
        }
        &__img{
            width: 46%;
            @include tablet{
                width: 100%; 
            }
            img{
                width: 100%;
                object-fit: contain;
            }
        }
    }
    &__sub{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 80px;
        width: 100%;
        &--img{
            padding: 10px 13px;
            border-radius: 5px;
            @include tablet{
                padding: 6px;
            }
            img{
                object-fit: contain;
                width: 30px;
                height: 30px; 
                @include tablet{
                    width: 22px;
                    height: 26px;
                }
            }
        }
        &--active{
            background: $color-main-blue;
        }
        &--line{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 7%;
            img{
                width: 201px;
                height: 2px;
                @include tablet{
                    width: 100%;
                }
            }
        }
    }
    &--active{
        border: 1px solid $color-main-blue;
    }
}