.policy{
    padding: 20px 40px;
    width: 100%;

    @include tablet{
        padding: 15px 16px;
    }

    h1{
        text-align: center;
        font-weight: 700;
        font-size: 35px;
    }

    &__container{
        margin-top: 48px;

        &__card{
            &:not(:first-child){
                margin: 46px 0;
            }
            
            h2{
                font-weight: 600;
                font-size: 20px;
            }

            h3{
                font-size: 25px;
                font-weight: 600px;
                color: $color-main-blue;
            }

            p{
                font-size: 16px;
                margin-top: 14px;
                font-weight: 300;
                a{
                    color: inherit;
                }
            }
            
            ul{
                margin-top: 16px;
                li{
                    font-weight: 300;
                    font-size: 16px;
                    list-style: inside;
                    margin-top: 3px;
                }
            }

        }
    }
}