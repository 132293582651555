.loan{
    margin: 43px 104px;
    font-family: $font-Jakarta;
    width: 100%;

    @include tablet{
        margin: 0 18px;
    }   

    h3{
        font-size: 22px;
        // line-height: 32px;
        font-weight: 400;
        margin-bottom: 40px;

        @include tablet{
            margin-top: 35px;
            margin-bottom: 16px;
        }
    }

    h6{
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 20px;
        color: $color-txt-grey;
    }

    p{
        margin-top: 20px;
        margin-bottom: 30px;
        text-align: end;
        padding: 0 20px;

        @include tablet{
            text-align: left;
            padding: 0;
            margin: 10px 0px 15px;
        }
    }

    button{
        margin-left: 25px;
        font-weight: 400;
        font-size: 18px;
        padding: 10px 28px;
        line-height: unset;

        @include tablet{
            width: 48%;
            margin-left: 0;
            font-size: 16px;
            padding: 8px 10px;

            &:only-child{
                width: fit-content;
                padding: 15px 20px;
            }
        }
    }

    .btn-transp{
        background-color: transparent;
        border: 1px solid $color-main-blue;
    }

    &_container{
        border-radius: 15px;
        background-color: $color-main-back;
        padding: 29px;
        display: flex;
        column-gap: 5%;
        flex-wrap: wrap;
        row-gap: 20px;

        p{
            font-size: 11px;
            font-family: $font-Jakarta;
            text-align: left;
            line-height: unset;
        }

        @include tablet{
            border-radius: 12px;
            padding: 18px;
            flex-direction: column;
        }

        &_inputs{
            width: 30%;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            
            @include tablet{
                width: 100%;
            }

            label{
                font-weight: 400;
                font-size: 15px;
                // line-height: 23px;
                margin-bottom: 10px;
            }

            input{
                padding: 13px 15px;
                background: transparent;
                outline-color: $color-main-blue;
                border: 1px solid $color-main-blue;
                border-radius: 5px;
                margin-bottom: 25px;
                font-size: 16px;
                color: #fff;
                font-family: $font-Jakarta;
                font-weight: 400;
                
                @include tablet{
                    margin-bottom: 20px;
                }
                
                &::placeholder{
                    font-weight: 400;
                    line-height: 20px;
                    font-size: 16px;
                    color: $color-txt-grey;
                }
                                
                &[type=file]{
                    display: none;
                }
            }
            
            &_block{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                
                input[type="radio"]{
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                }
            }
            
            select{
                font-family: $font-Jakarta;
                padding: 13px 15px;
                background: transparent;
                outline-color: $color-main-blue;
                border: 1px solid $color-main-blue;
                border-radius: 5px;
                color: #fff;
                font-size: 16px;
                text-transform: capitalize;

                @include tablet{
                    margin-bottom: 20px;
                }

                option{
                    font-weight: 400;
                    font-size: 16px;
                    color: $color-txt-grey;
                    background: $color-main-back;
                    text-transform: capitalize;

                    &::after{
                        background: $color-main-back;
                    }
                }
            }

            &_btn{
                background-color: $color-blue-medium;
                margin-left: 0 !important;
                margin-top: 0;
                border-radius: 5px;
                border: 1px solid $color-main-blue;
                font-size: 15px !important;
                font-weight: 500;
                padding: 7px 18px;

                @include tablet{
                    width:180px !important;
                }
            }

            &_file{
                display: flex;
                justify-content: center;
                text-align: end;
                column-gap: 20px;
                align-items: center;

                @include tablet{
                    flex-direction: column;
                    align-items: flex-start;
                    text-align: left;
                    row-gap: 10px;
                }

                div{
                    width: 50%;
                    
                    @include tablet {
                        width: 100%;
                    }
                }
            }
        }
    }

    &_btns{
        display: flex;
        width: 100%;
        margin-bottom: 25px;

        @include tablet{
            justify-content: space-between;
        }
    }

    &_page{
        margin: 43px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
            margin: 0;
        }

        &-bullet{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $color-range-bg-grey;
            

            @include tablet{
                width: 23px;
                height: 23px;
            }

            &-active{
            background-color: $color-main-blue;
            }
        }

        &-line{
            width: 57px;
            height: 1px;
            background-color: $color-range-bg-grey;

            @include tablet{
                width: 30px;
            }

            &-active{
                background-color: $color-main-blue;
            }
        }

        div{
            display: flex;
            align-items: center;
        }

        &-active{
            background-color: $color-main-blue;
        }
    }
}

