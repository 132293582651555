@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$color-silver-background: rgba(#243665, .05);
$color-btn-background: #243665;
$color-txt-white: #fff;
$color-main-black: #18181A;
$color-main-blue:  #2A70FE;
$color-main-back: #26282C;
$color-main-back-light: #3E3E3E;
$color-banner-btn: #FF815C;
$color-silver-home: #ABB0BE;
$color-background-blue: rgba(42, 112, 254, 0.1);
$color-border-silver: #888888;
$color-scroll-bar-thumb: #A0A0A0;
$color-border-notes: #969696;
$color-background-blue:  rgba(20, 94, 238, 0.6);
$color-white: #fff;
$color-nav:  #E5E5E7;
$font-Jakarta: 'Plus Jakarta Sans';
$font-Poppins: 'Poppins', sans-serif;

$color-background-light-black: #232426;
$color-txt-grey: #DDDDDD;
$color-range-bg-grey: #D9D9D9;
$color-range-bg-green: #2AFEED;

$color-blue-dark: rgba(#2A70FE,0.6);
$color-blue-medium: rgba(#2A70FE,0.3);
$color-blue-light: #9656FF;
$color-dark-grey: #A9A9A9;
$color-green: #2AA918;
$color-grey: #454545;

$margin-top-side: 30px;
$color-txt-grey: #BFBFBF;

$color-loading-bc: #D9D9D9;
$color-Inprogress: #6C4EC1;
$color-Approve: #2AC769;
$color-Assign: #22C7E8;
$color-percentage-background: #939393;
$color-quiz-percent-bc: #CDCDCD;
$color-quiz-percent-fill: #2AFEED;
$color-main-back-border: #676767;
$color-placement-basic: #00CF5E;
$color-placement-inter: #DE6B02;
$color-placement-adv: #CF1900;
$color-silver-txt: #D1D1D1;
$color-mock-select: #07A37E;
$color-black-background: #202024;
$color-silver-border: #5E6779;

$background-gradient: linear-gradient(0deg, rgba(27, 29, 33, 0.10) -1110.47%, rgba(28, 37, 55, 0.10) -690.39%, rgba(30, 46, 79, 0.10) -403.17%, rgba(33, 61, 119, 0.10) -185.29%, rgba(36, 79, 167, 0.10) -36.73%, rgba(42, 112, 254, 0.10) 100%), #18181A;
$color-silver: #888888;
$color-back-silver: #24272D;
$color-txt-silver: #95A1B8;
$color-txt-blue: #768BB4;
$color-blue-bc: #0E2657;
$color-assessment-bc: #131315;