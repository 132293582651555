.placement{
    &__route{
        padding: 30px 40px;
        width: 100%;

        @include tablet{
            padding: 30px 0;
        }

        h1{
            font-size: 22px;
            font-weight: 500;
        }

        &__container{
            display: flex;
            column-gap: 38px;
            margin-top: 30px;
            width: 100%;

            @include tablet{
                flex-wrap: wrap;
                column-gap: unset;
                justify-content: space-between;
                row-gap: 50px;
            }
            
            &__card{
                position: relative;
                width: 20%;
                color: $color-white;
                text-decoration: none;
                background-size: 100% 100%;
                background-repeat: no-repeat;

                @include tablet{
                    width: 48%;
                }

                h3{
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    margin-top: -40px;
                }

                &--lock{
                    position: absolute;
                    left: 0%;
                    top: -1%;
                    width: 100%;
                    height: 108.5%;
                }

                &--main{
                    width: 100%;
                    // height: 228.011px;
                    object-fit: contain;
                }

                &:hover{
                    .placement__route__container__card--tooltip{
                        display: flex;

                        @include tablet{
                            display: none;
                        }
                    }
                }

                &--tooltip{
                    position: absolute;
                    left: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url('../assets/placement/tooltip.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    display: none;
                    align-items: center;
                    justify-content: center;
                    z-index: 10;
                    width: 260px;
                    padding: 11px 7px;

                    &--bottom{
                        top: 150%;
                        left: 0;
                        // bottom: -10%;
                    }

                    @include tablet{
                        display: none;
                    }

                    p{
                        font-size: 15px;
                        margin-left: 12.5px;
                        text-align: center;
                        font-weight: 300;
                    }
                }

                
            }
        }
    }

    &__resume{
        padding: 30px 40px;
        width: 100%;

        @include tablet{
            padding: 30px 0;
        }

        h1{
            font-size: 22px;
            font-weight: 600;

            @include tablet{
                font-size: 20px;
            }
        }

        &__modal{
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(86, 86, 86);
            background-color: rgba(0, 0, 0, 0.63);
            z-index: 9999;
            font-family: $font-Jakarta;

            &__container{
                background-color: #fefefe;
                margin: 5% auto; /* 15% from the top and centered */
                padding: 20px 25px;
                border: 1px solid #888;
                width: 54%; /* Could be more or less, depending on screen size */
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                row-gap: 4%;
                background: #000;
                border-radius: 10px;

                @include tablet{
                    width: 95%;
                    margin-top: 22%;
                }

                &__pdf{
                    height: 400px;
                    width: 100%;
                }

                &__btn{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    column-gap: 20px;
                    margin-top: 20px;

                    button{
                        border: 1px solid $color-main-blue;
                        color: $color-white;
                        padding: 7px 22px;
                        font-size: 15px;
                        font-weight: 500;
                        border-radius: 5px;
                        cursor: pointer;
                        
                        &:first-child{
                            background-color: transparent;
                        }

                        &:last-child{
                            background-color: $color-main-blue;
                        }

                    }
                }
            }
        }

        &__sample{
            background-color: $color-main-back;
            padding: 18px 25px;
            margin-top: 30px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;

            @include tablet{
                flex-direction: column;
                padding: 19px 10px;  
            }

            &--left{
                width: 48%;

                @include tablet{
                    width: 100%;
                }

                h2{
                    font-weight: 600;
                    font-size: 20px;

                    @include tablet{
                        font-size: 15px;
                    }
                }
    
                ul{
                    list-style: none;
                    margin-top: 18px;
    
                    li{
                        margin: 12px 0;
                        font-size: 15px;
                        display: flex;
                        align-items: center;
                        column-gap: 10px;

                        @include tablet{
                            font-size: 14px;
                        }

                        img{
                            height: 9px;
                        }
                    }
                }
            }

            &--right{
                width: 48%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include tablet{
                    width: 100%;
                    margin-top: 30px;
                }

                &__pdf{
                    width: 251px;
                    height: 350px;
                    overflow: hidden;
                    border-radius: 5px;

                    @include tablet{
                        width: 90%;
                        height: 400px;
                    }
                }
            }

            button{
                color: $color-white;
                border: 2px solid $color-main-blue;
                font-size: 17px;
                background-color: transparent;
                padding: 12px 22px;
                margin-top: 15px;
                border-radius: 5px;
                cursor: pointer;
                display: flex;
                align-items: center;
                column-gap: 9px;
                margin-top: 22px;

                img{
                    height: 24px;
                    object-fit: contain;
                }
            }
        }

        &__template{
            background-color: $color-main-back;
            padding: 15px 25px;
            margin-top: 30px;
            border-radius: 10px;

            @include tablet{
                padding: 15px 12px;
            }

            h2{
                font-weight: 600;
                font-size: 20px;
            }

            &--file{
                display: none;
            }

            &__container{
                display: flex;
                align-items: stretch;
                // justify-content: space-between;
                column-gap: 22px;
                row-gap: 22px;
                margin-top: 20px;
                // flex-wrap: wrap;
                overflow-x: scroll;
                padding-bottom: 27px;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                  }
                
                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    background: white !important; 
                  }
                
                  /* Handle on hover */
                  &::-webkit-scrollbar-thumb:hover {
                    background: #ccc !important; 
                  }

                &--pdf{
                    min-width: 22%;
                    height: 320px;
                    border-radius: 5px;
                    overflow: hidden;
                    position: relative;
                    overflow: hidden;

                    @include tablet{
                        min-width: 80%;
                    }

                    &--viewer{
                        width: 100%;
                        height: 100%;
                    }

                    &--download{
                        position: absolute;
                        right: 1%;
                        bottom: 1%;
                        height: 44px;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }
            }
        }

        &__upload{
            background-color: $color-main-blue;
            color: $color-white;
            display: flex;
            align-items: center;
            column-gap: 14px;
            font-size: 16px;
            padding: 15px 17px;
            border: none;
            border-radius: 5px;
            font-weight: 400;
            margin-top: 35px;
            border: 1px solid $color-main-blue;
            cursor: pointer;

            img{
                height: 20px;
                object-fit: contain;
            }
        }

        #pdf-controls {
            display: none;
        }

        .hysiap{
            overflow: hidden;
        }

        .react-pdf__Page__canvas{
            height: 100% !important;
            width: 100% !important;
            object-fit: cover;
        }

        #pdf-page-wrapper{
            margin: 0 !important;
        }
    }

    &__interview{
        padding: 20px 40px;
        width: 100%;

        @include tablet{
            padding: 30px 0;
        }

        h1{
            font-size: 22px;
            font-weight: 400;
        }

        &__container{
            margin-top: 20px;
            width: 100%;
            display: flex;
            column-gap: 22px;
            row-gap: 21px;
            flex-wrap: wrap;

            @include tablet{
                justify-content: space-between;
                column-gap: unset;
            }

            &__card{
                width: 18%;
                background-color: $color-main-back;
                display: flex;
                flex-direction: column;
                padding: 20px 10px;
                border: 1px solid #676767;
                border-radius: 5px;
                text-decoration: none;
                color: $color-white;

                @include tablet{
                    width: 48%;
                }

                img{
                    width: 80px;
                    height: 80px;
                    object-position: left;
                    object-fit: contain;
                }

                h3{
                    font-size: 17px;
                    font-weight: 700;
                    margin-top: 9px;
                    margin-bottom: 25px;
                }

                &__status{
                    width: 100%;
                    height: 4px;
                    background-color: #D9D9D9;
                    margin-top: 14px;
                    margin-bottom: 7px;
                    border-radius: 2px;

                    span{
                        display: block;
                        width: 2%;
                        height: 100%;
                        background-color: $color-quiz-percent-fill;
                    }
                }

                p{
                    font-size: 12px;
                    font-weight: 400;
                }

                button{
                    width: fit-content;
                    padding: 9px 15px;
                    border: 1px solid $color-main-blue;
                    background-color: transparent;
                    border-radius: 5px;
                    margin-top: 15px;
                    font-size: 15px;
                    font-weight: 500;
                    color: $color-white;
                    cursor: pointer;
                }
            }
        }

        &__list{
            padding: 20px 0;
            width: 100%;

            h1{
                font-size: 22px;
                font-weight: 400;
            }

            &__container{
                width: 100%;

                &__card{
                    border-radius: 5px;
                    border: 1px solid $color-main-back-border;
                    background: $color-main-back;
                    padding: 21px 24px;
                    width: 80%;
                    margin-top: 31px;

                    @include tablet{
                        width: 100%;
                        padding: 15px 20px;
                    }

                    &:nth-child(2){
                        .placement__interview__list__container__card__stats{
                            h5{
                                background-color: rgba($color-placement-inter, .3);
                                border: 2px solid rgba($color-placement-inter, 1);
                            }
                        }
                    }
                    
                    &:nth-child(3){
                        .placement__interview__list__container__card__stats{
                            h5{
                                background-color: rgba($color-placement-adv, .3);
                                border: 2px solid rgba($color-placement-adv, 1);
                            }
                        }
                    }

                    &__title{
                        display: flex;
                        align-items: center;
                        column-gap: 10px;

                        img{
                            height: 70px;
                            width: 70px;
                            object-fit: contain;
                        }

                        h3{
                            font-size: 16px;
                            font-weight: 700;
                        }
                    }

                    &__stats{
                        display: flex;
                        align-items: center;
                        column-gap: 15px;
                        margin-top: 20px;

                        @include tablet{
                            flex-wrap: wrap;
                            flex-direction: column;
                            row-gap: 20px;
                            align-items: flex-start;
                        }

                        &--basic{
                            background-color: rgba($color-placement-basic, .3);
                            border: 2px solid rgba($color-placement-basic, 1);
                        }

                        &--inter{
                            background-color: rgba($color-placement-inter, .3);
                            border: 2px solid rgba($color-placement-inter, 1);
                        }

                        &--adv{
                            background-color: rgba($color-placement-adv, .3);
                            border: 2px solid rgba($color-placement-adv, 1);
                        }

                        h5{
                            padding: 6px 0px;
                            border-radius: 5px;
                            font-size: 16px;
                            font-weight: 500;
                            margin-right: 20px;
                            min-width: 174px;
                            text-align: center;
                        }

                        p{
                            font-size: 17px;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            column-gap: 10px;

                            img{
                                height: 19px;
                                object-fit: contain;
                            }
                        }

                        button{
                            font-size: 15px;
                            font-weight: 600;
                            background-color: $color-main-blue;
                            border: none;
                            padding: 10px 15px;
                            font-weight: 400;
                            color: $color-white;
                            border-radius: 5px;
                            cursor: pointer;
                            margin-left: auto;
                        }
                        
                    }
                }
            }
        }

        &__detail{
            width: 100%;
            padding: 30px 40px;

            @include tablet{
                padding: 30px 0;
            }

            h4{
                font-size: 20px;
                font-weight: 700;
            }

            &__container{
                border-radius: 10px;
                border: 1px solid #2A70FE;
                margin-top: 25px;
                padding: 22px 25px;
                width: 80%;

                @include tablet{
                    width: 100%;
                }

                p{
                    font-size: 15px;
                    font-weight: 400;
                    &:not(:first-child){
                        margin: 18px 0;
                    }
                }

                &__btn{
                    margin-top: 40px;

                    h6{
                        border: 1px solid $color-white;
                        width: fit-content;
                        font-size: 15px;
                        font-weight: 500;
                        padding: 10px 15px;
                        border-radius: 5px;
                        cursor: pointer;
                        margin: 30px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        column-gap: 20px;

                        img{
                            height: 28px;
                            object-fit: contain;
                        }
                    }
                }

            }

            &__submit{
                input{
                    display: none;
                }

                button{
                    background-color: $color-main-blue;
                    padding: 12px 18px;
                    margin-top: 28px;
                    color: $color-white;
                    border: none;
                    font-size: 18px;
                    border-radius: 5px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 10px;
                    text-transform: capitalize;

                    img{
                        height: 22px;
                        object-fit: contain;
                    }
                }

                &__solution{
                    margin-top: 45px;

                    h4{
                        font-size: 18px;
                        font-weight: 700;
                    }

                    p{
                        cursor: pointer;
                        font-size: 14px;
                        margin: 25px 0;
                        text-decoration: underline;
                        color: rgba(#fff, .8);
                    }
                }
            }
        }

        &__modal{
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(86, 86, 86);
            background-color: rgba(0, 0, 0, 0.63);
            z-index: 9999;
            font-family: $font-Jakarta;

            &__container{
                background-color: #fefefe;
                margin: 5% auto; /* 15% from the top and centered */
                padding: 20px 25px;
                border: 1px solid #888;
                width: 54%; /* Could be more or less, depending on screen size */
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                row-gap: 4%;
                background: #000;
                border-radius: 10px;

                @include tablet{
                    width: 95%;
                    margin-top: 22%;
                }

                &__pdf{
                    height: 400px;
                    width: 100%;
                }

                &__btn{
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    column-gap: 20px;
                    margin-top: 20px;

                    button{
                        border: 1px solid $color-main-blue;
                        color: $color-white;
                        padding: 7px 22px;
                        font-size: 15px;
                        font-weight: 500;
                        border-radius: 5px;
                        cursor: pointer;
                        
                        &:first-child{
                            background-color: transparent;
                        }

                        &:last-child{
                            background-color: $color-main-blue;
                        }

                    }
                }
            }
        }
    }

    &__mock{
        padding: 30px 40px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include tablet{
            flex-direction: column;
            padding: 30px 0;
            padding-bottom: 50px;
        }

        &__title{
            width: 50%;

            @include tablet{
                width: 100%;
            }
        
            h1{
                font-size: 22px;
                font-weight: 500;
            }
            
            p{
                font-size: 16px;
                margin-top: 12px;
                color: $color-silver-txt;
                line-height: 30px;
            }

            &__btn{
                display: flex;
                align-items: center;
                column-gap: 30px;
                margin-top: 32px;

                @include tablet{
                    flex-direction: column;
                    row-gap: 30px;
                    align-items: stretch;
                    justify-content: flex-start;
                }

                h4{
                    border: 2px solid #07A27E;
                    padding: 10px 15px;
                    border-radius: 5px;
                    font-weight: 400;
                }

                button{
                    background-color: $color-main-blue;
                    padding: 10px 17px;
                    color: $color-white;
                    border: none;
                    font-size: 16px;
                    border-radius: 5px;
                    position: relative;
                    cursor: pointer;

                    @include tablet{
                        padding: 15px;
                        font-size: 19px;
                        width: 100%;
                    }

                    img{
                        position: absolute;
                        left: 0%;
                        top: 0%;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        &__book{
            width: 38%;

            @include tablet{
                width: 100%;
                margin-top: 40px;
            }

            &__title{
                display: flex;
                align-items: baseline;
                column-gap: 20px;

                h2{
                    font-size: 20px;
                    font-weight: 400;
                }

                p{
                    font-size: 15px;
                    img{
                        height: 10px;
                        object-fit: contain;
                        vertical-align: middle;
                        margin-right: 8px;
                    }
                }
            }

            &__calendar{
                margin-top: 15px;
            }

            &__time{
                margin-top: 42px;
                h4{
                    font-size: 17px;
                    font-weight: 400;
                }
                
                div{
                    width: 70%;
                    display: flex;
                    column-gap: 12px;
                    overflow-x: scroll;
                    margin-top: 22px;
                    padding-bottom: 10px;

                    @include tablet{
                        width: 100%;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 5px;
                      }
                    
                      /* Handle */
                      &::-webkit-scrollbar-thumb {
                        background: white !important; 
                      }
                    
                      /* Handle on hover */
                      &::-webkit-scrollbar-thumb:hover {
                        background: #ccc !important; 
                      }
                    
                    p{
                        min-width: fit-content;
                        font-size: 17px;
                        padding: 7px 8px;
                        border: 0.657px solid #787878;
                        border-radius: 6.57px;
                        color: #A1A1A1;
                        cursor: pointer;
                        margin-bottom: 10px;

                        &:nth-child(2), &:nth-child(3){
                            border: 1.156px solid #07A37E !important;
                            color: $color-white !important;
                        }
                    }
                }
                
                &__active{
                    background-color: #07A37E !important;
                    color: $color-white !important;
                }

            }

            &__btn{
                background-color: $color-main-blue;
                padding: 12px 18px;
                margin-top: 28px;
                color: $color-white;
                border: none;
                font-size: 18px;
                border-radius: 5px;
                cursor: pointer;
            }
        }

        &__modal{
            position: fixed; /* Stay in place */
            z-index: 1; /* Sit on top */
            left: 0;
            top: 0;
            width: 100%; /* Full width */
            height: 100%; /* Full height */
            overflow: auto; /* Enable scroll if needed */
            background-color: rgb(86, 86, 86);
            background-color: rgba(0, 0, 0, 0.63);
            z-index: 9999;
            font-family: $font-Jakarta;

            &__container{
                background-color: #fefefe;
                margin: 5% auto; /* 15% from the top and centered */
                padding: 20px 25px;
                border: 1px solid #888;
                width: 40%; /* Could be more or less, depending on screen size */
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                row-gap: 4%;
                background: #000;
                border-radius: 10px;

                @include tablet{
                    width: 90%;
                    margin-top: 42%;
                }

                h2{
                    font-size: 22px;
                    font-weight: 500;
                    margin-bottom: 20px;

                    @include tablet{
                        font-size: 25px;
                    }
                }

                p{
                    font-size: 15px;

                    @include tablet{
                        font-size: 19px;
                    }
                    &:nth-child(3){
                        margin-top: 5px;
                        font-weight: 700;

                        @include tablet{
                            margin-top: 15px;
                        }
                    }
                }

                &__btn{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    column-gap: 20px;
                    margin-top: 40px;

                    button{
                        border: 1px solid $color-main-blue;
                        color: $color-white;
                        padding: 9px 22px;
                        font-size: 17px;
                        font-weight: 600;
                        border-radius: 5px;
                        cursor: pointer;
                        
                        &:first-child{
                            background-color: $color-main-blue;
                        }
                        
                        &:last-child{
                            background-color: transparent;
                        }

                    }
                }

            }
        }

    }

    &__jobportal{
        padding: 20px 40px;
        width: 100%;

        @include tablet{
            padding: 20px 0;
        }

        h1{
            font-size: 20px;
            font-weight: 500;
        }

        &__filter{
            padding: 27px 28px;
            background-color: $color-main-back;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            border-radius: 10px;

            @include tablet{
                flex-direction: column;
                padding: 27px 10px;
                row-gap: 40px;
            }

            &__card{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 23px 24px;
                width: 30%;
                border-radius: 10px;
                cursor: pointer;

                @include tablet{
                    width: 100%;
                    
                }
                
                &:first-child{
                    background-color: #69E0C4;
                    box-shadow: 8px 8px 0px 0px #FFF;
                }

                &:nth-child(2){
                    background-color: #FD8232;
                }

                &:last-child{
                    border-radius: 10px;
                    background: #4CE69C;
                }
                
                p{
                    font-size: 20px;
                    color: #000;
                    border-bottom: 1px solid #000;
                    width: fit-content;
                    padding-bottom: 12px;
                }

                img{
                    width: 82px;
                    height: 81.39px;
                    object-fit: contain
                }
            }
        }

        &__container{
            margin-top: 36px;
            background-color: $color-main-back;
            padding: 23px 22px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;

            @include tablet{
                flex-direction: column;
                padding: 23px 10px;
                row-gap: 22px;
            }

            &__list{
                width: 37%;
                
                &__container{
                    height: 780px;
                    overflow-y: scroll;
                    padding-right: 20px;

                    @include tablet{
                        padding-right: 5px;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 5px;
                      }
                    
                      /* Handle */
                      &::-webkit-scrollbar-thumb {
                        background: white !important; 
                      }
                    
                      /* Handle on hover */
                      &::-webkit-scrollbar-thumb:hover {
                        background: #ccc !important; 
                      }
                }

                &__pagination{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 22px;
                    font-size: 15px;
                    column-gap: 10px;
                    font-weight: 700;

                    img{
                        height: 17px;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }

                @include tablet{
                    width: 100%;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 5px;
                  }
                
                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    background: white !important; 
                  }
                
                  /* Handle on hover */
                  &::-webkit-scrollbar-thumb:hover {
                    background: #ccc !important; 
                  }

                &__card{
                    width: 100%;
                    padding: 22px 24px;
                    background-color: $color-main-black;
                    border-radius: 12px;
                    cursor: pointer;
                    position: relative;
                    
                    &:not(:first-child){
                        margin-top: 20px;
                    }
                    
                    &__title{
                        display: flex;
                        column-gap: 16px;

                        img{
                            width: 53px;
                            height: 52px;
                            border-radius: 26px;
                            object-fit: contain;
                        }

                        h4{
                            font-size: 17px;
                            font-weight: 600;
                        }

                        p{
                            font-size: 14px;
                            margin-top: 6px;
                        }
                    }

                    ul{
                        list-style: inside;

                        li{
                            font-size: 12px;
                            color: #DFDFDF;
                            height: 15px;
                            overflow: hidden;
                        }
                    }

                    &__stats{
                        display: flex;
                        column-gap: 20px;
                        margin-top: 30px;
                        margin-bottom: 20px;

                        @include tablet{
                            overflow-x: scroll;
                        }

                        p{
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            column-gap: 8px;

                            @include tablet{
                                min-width: fit-content;
                            }

                            img{
                                width: 15px;
                                height: 15px;
                                object-fit: contain;
                            }
                        }
                    }

                    &__skills{
                        margin-top: 20px;
                        display: flex;
                        column-gap: 10px;
                        flex-wrap: wrap;
                        row-gap: 13px;

                        p{
                            padding: 7px 10px;
                            background-color: $color-main-back;
                            // width: fit-content;
                            // width: 100px;
                            font-size: 12px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            border-radius: 5px;
                        }
                    }

                    &__date{
                        margin-top: 22px;
                        font-size: 10px;
                        color: #DFDFDF;
                    }

                    &__btn{
                        display: none;

                        @include tablet{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $color-main-blue;
                            font-size: 15px;
                            padding: 8px 17px;
                            color: $color-white;
                            text-decoration: none;
                            border-radius: 5px;
                            font-weight: 500;
                            cursor: pointer;
                            width: fit-content;

                            position: absolute;
                            bottom: 4%;
                            right: 4%;
                        }
                    }
                }
            }

            &__detail{
                width: 61%;

                @include tablet{
                    width: 100%;
                    display: none;
                }

                &__card{
                    background-color: $color-main-black;
                    width: 100%;
                    padding: 22px 24px;
                    border-radius: 12px;
                    height: 100%;

                    &__title{
                        display: flex;
                        column-gap: 16px;

                        img{
                            width: 53px;
                            height: 52px;
                            border-radius: 26px;
                            object-fit: contain;
                        }

                        h4{
                            font-size: 17px;
                            font-weight: 600;
                        }

                        p{
                            font-size: 14px;
                            margin-top: 6px;
                        }
                    }

                    &__stats{
                        display: flex;
                        column-gap: 20px;
                        margin-top: 30px;
                        margin-bottom: 20px;

                        p{
                            font-size: 14px;
                            display: flex;
                            align-items: center;
                            column-gap: 8px;

                            img{
                                width: 18px;
                                height: 18px;
                                object-fit: contain;
                            }
                        }
                    }

                    &__skills{
                        margin-top: 20px;
                        display: flex;
                        column-gap: 10px;
                        flex-wrap: wrap;
                        row-gap: 14px;

                        p{
                            padding: 7px 10px;
                            background-color: $color-main-back;
                            width: fit-content;
                            font-size: 12px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            border-radius: 5px;
                        }
                    }

                    &__date{
                        margin-top: 35px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        
                        p{
                            font-size: 12px;
                            color: #DFDFDF;
                            vertical-align: bottom;
                        }

                        a{
                            background-color: $color-main-blue;
                            font-size: 15px;
                            padding: 12px 22px;
                            color: $color-white;
                            text-decoration: none;
                            border-radius: 10px;
                            font-weight: 500;
                            cursor: pointer;
                        }
                    }

                    &__border{
                        height: 2px;
                        border-bottom: 2px solid #838383;
                        margin-top: 19px;
                    }

                    &__details{
                        margin-top: 14px;

                        h2{
                            font-size: 14px;
                            font-weight: 500;
                        }

                        ul{
                            list-style: inside;
                            height: 400px;
                            overflow-y: scroll;

                            &::-webkit-scrollbar {
                                width: 5px;
                                height: 5px;
                              }
                            
                              /* Handle */
                              &::-webkit-scrollbar-thumb {
                                background: white !important; 
                              }
                            
                              /* Handle on hover */
                              &::-webkit-scrollbar-thumb:hover {
                                background: #ccc !important; 
                              }
    
                            li{
                                font-size: 15px;
                                font-weight: 400;
                                margin: 12px 0;
                                color: #DFDFDF;
                            }
                        }
                    }
                    
                    &__quali{
                        margin-top: 22px;

                        p{
                            font-size: 14px;
                            margin: 10px 0; 
                        }
                    }
                }
            }

            &__portal{

                &__container{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: stretch;
                    row-gap: 30px;

                    @include tablet{
                        flex-direction: column;
                    }
                }

                &__card{
                    width: 48%;
                    padding: 22px 24px;
                    background-color: $color-main-black;
                    border-radius: 12px;
                    cursor: pointer;
                    position: relative;

                    @include tablet{
                        width: 100%;
                    }
                    
                    &__title{
                        display: flex;
                        column-gap: 16px;

                        img{
                            width: 53px;
                            height: 52px;
                            border-radius: 26px;
                            object-fit: contain;
                        }

                        h4{
                            font-size: 17px;
                            font-weight: 600;
                        }

                        p{
                            font-size: 14px;
                            margin-top: 6px;
                        }
                    }

                    ul{
                        list-style: inside;

                        li{
                            font-size: 12px;
                            color: #DFDFDF;
                            height: 15px;
                            overflow: hidden;
                        }
                    }

                    &__stats{
                        display: flex;
                        column-gap: 20px;
                        margin-top: 30px;
                        margin-bottom: 20px;

                        @include tablet{
                            overflow-x: scroll;
                        }

                        p{
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            column-gap: 8px;

                            @include tablet{
                                min-width: fit-content;
                            }

                            img{
                                width: 15px;
                                height: 15px;
                                object-fit: contain;
                            }
                        }
                    }

                    &__skills{
                        margin-top: 20px;
                        display: flex;
                        column-gap: 10px;
                        flex-wrap: wrap;
                        row-gap: 13px;

                        p{
                            padding: 7px 10px;
                            background-color: $color-main-back;
                            // width: fit-content;
                            // width: 100px;
                            font-size: 12px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            border-radius: 5px;
                        }
                    }

                    &__date{
                        margin-top: 22px;
                        font-size: 10px;
                        color: #DFDFDF;
                    }

                    &__btn{
                        // display: none;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        margin-left: auto;
                        background-color: $color-main-blue;
                        font-size: 15px;
                        padding: 8px 17px;
                        color: $color-white;
                        text-decoration: none;
                        border-radius: 5px;
                        font-weight: 500;
                        cursor: pointer;
                        width: fit-content;
                    }
                }

                &__pagination{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 22px;
                    font-size: 15px;
                    column-gap: 10px;
                    font-weight: 700;

                    img{
                        height: 17px;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }
            }
        }
    }
};

.react-calendar{
    background-color: $color-main-back;
    border-radius: 15.592px;
    font-family: $font-Jakarta;
    font-size: 18px;
    font-weight: 600;
    margin-top: 40px;
    border: none;

    @include tablet{
        width: 100%;
    }
}

.react-calendar__tile{
    color: $color-white;
    font-size: 14px;
    font-weight: 500;
}

.react-calendar__tile:disabled{
    background-color: transparent;
    color: #666;
}

.react-calendar__month-view__weekdays__weekday{
    background-color: transparent;
    color: $color-main-blue;
    font-weight: 600;
}

.react-calendar__navigation__label{
    color: $color-white;
}

.react-calendar__navigation button:disabled {
    background-color: transparent;
}

.react-calendar__navigation__arrow{
    color: $color-white;
}

.react-calendar__tile--now, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    background-color: $color-mock-select;
    // border: 1px solid $color-white;
}

// .react-calendar__navigation button:enabled:hover, .react-calendar__tile:enabled:hover{
//     background-color: transparent;
// }