.container{
    max-width: 1440px;
    margin: 0 auto;
}

.btn{
    width: fit-content;
    background-color: ($color-main-blue);
    color: $color-txt-white;
    padding: 11px 0;
    text-align: center;
    border-radius: 8px;
    margin-top: 30px;
    font-weight: 500;
    font-size: 17px;
    font-family: $font-Jakarta;
    padding: 8px 20px;
    border: none;
    outline: none;
    line-height: 40px;
    cursor: pointer;
    @include tablet{
        width: 100%;
    }        
}

.course__card{
        width: 23.5%;
        border: 1px solid $color-main-blue;
        border-radius: 10px;
        box-shadow: 1px 1px 3px $color-main-blue;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;
        color: inherit;

        @include tablet{
            min-width: 100%;
            border: 1px solid $color-main-blue;
            // border-radius: 40px;
        }

        img{
            width: 100%;
            object-fit: cover;
            height: 158px;

            @include tablet{
                width: 100%;
                height: 220px;
                object-fit: cover;
            }
        }

        &__textBox{
            padding: 11px 11px 18px ;

            @include tablet{
                padding: 12px 12px 20px;
            }

            h3{
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                min-height: 75px;

                @include tablet{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: unset;
                    height: 80px;
                }
            }

            p{
                margin-top: 20px;
                font-weight: 400;
                font-size: 14px; 
                line-height: 17px;
                color: $color-txt-grey;
                display: flex;
                justify-content: space-between;

                @include tablet{
                    margin-top: 25px;
                    font-size: 16px; 
                    line-height: 17px;
                    font-weight: 400;
                }

                span{
                    color: $color-txt-white;
                    img{
                        margin-left: 5px;
                        width: 15px;
                        height: 15px;
                    }
                }
            }

            &_range{
                margin-top: 8px;
                height: 2px;
                background-color: $color-range-bg-grey;
                width: 100%;

                @include tablet{
                    height:3px;
                    margin-top: 12px;
                }

                span{
                    width: 0;
                    height: 2px;
                    background-color: $color-range-bg-green;
                    display: block;
                }
            }
           
        }
}