.video{
    padding: 16px 10px;
    padding-left: 40px;
    margin-top: 10px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;

    @include tablet{
        flex-direction: column;
        padding: 0;
        width: 100%;
    }

    &--active{
        color: $color-main-blue;
        border-bottom: 2px solid $color-main-blue;
        z-index: 2;
    }

    &__player{
        width: 68%;

        &--full{
            width: 100%;
            padding: 0 80px;
            
            @include tablet{
                padding: 0;
            }
        }

        @include tablet{
            width: 100%;
        }

        .plyr{
            width: 100% !important;
            @include tablet{
                height: 220px;
            }
        }

        &__live{
            width: 100%;
            min-height: 426px;
            background-color: $color-main-back;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
            padding: 30px;

            @include tablet{
                overflow: hidden;
            }

            a{
                color: inherit;
                text-decoration: none;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                overflow-wrap: break-word;

                @include tablet{
                    font-size: 16px;
                }
            }
            .btn{
                margin-top: 0;
                font-size: 16px;
                font-weight: 700;
                line-height: unset;
                padding: 12px 32px;
            }
        }

        &__features{
            margin-top: 31px;
            margin-bottom: 30px;
            
            &--title{
                display: flex;
                width: fit-content;
                column-gap: 80px;
                border-bottom: 0.4px solid #828282;

                @include tablet{
                    column-gap: 21px;
                    width: 100%;

                    @include tablet{
                        overflow-x: scroll;
                    }
                }

                h1{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 23px;
                    padding-bottom: 13.48px;
                    cursor: pointer;

                    @include tablet{
                        min-width: fit-content;
                    }
                }
            }

            &--notes{
                margin: 30px 0;

                &__rte{
                    padding: 17px 18px;
                    background-color: $color-main-back;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    cursor: pointer;

                    &--btns{
                        display: flex;
                        margin-top: 30px;
                        margin-bottom: 41px;
                        button{
                            margin-top: 0;
                            padding: 8px 43px;
                            margin-right: 21px;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 23px;
                            border-radius: 8px;
                            &:last-child{
                                background-color: transparent;
                                color: $color-main-blue;
                                border: 1px solid $color-main-blue;
                            }
                        }
                    }

                }

                &__container{
                    &__card{
                        padding: 19px 16px;
                        border: 1px solid $color-border-notes;
                        border-radius: 10px;
                        margin-top: 34px;

                        h3{
                            font-weight: 500;
                            font-size: 17px;
                            line-height: 30px;
                            margin-bottom: 10px;
                        }

                        h5{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 30px;
                            margin-bottom: 15px;
                            display: flex;
                            align-items: center;

                            span{
                                background-color: $color-background-blue;
                                padding: 6px 10px;
                                display: inline-block;
                                margin-right: 14px;
                                border-radius: 5px;
                                cursor: pointer;
                            }
                        }
                        
                        p{
                            padding: 10px 6px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            background-color: $color-main-back;

                            h1,h2,h3{
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &--resource{
                margin-top: 31px;
                width: 100%;

                &__btns{
                    display: flex;
                    column-gap: 30px;
                    
                    @include tablet{
                        flex-wrap: wrap;
                        column-gap: 21px;
                        row-gap: 23px;
                    }

                    button{
                        padding: 9px 17px;
                        width: fit-content;
                        border: none;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 23px;
                        border-radius:  5px;
                        background: transparent;
                        color: $color-white;
                        border: 1px solid $color-white;
                        cursor: pointer;
                    }
                }

                &--active{
                    background-color: $color-white !important;
                    color: $color-main-black !important;
                }

                &__container{
                    margin-top: 36px;

                    span{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        column-gap: 36px;
                        row-gap: 49px;

                        @include tablet{
                            column-gap: 12px;
                        }
                    }
                    
                    &__study{
                        width: 230px;
                        min-height: 327px;
                        padding: 13px 10px;
                        background-color: $color-main-back;
                        box-shadow: 5px 5px 0px #145EEE;
                        border-radius: 10px;
                        cursor: pointer;
                        color: #fff;
                        text-decoration: none;

                        @include tablet{
                            width: 80%;
                            padding: 10px 14px;
                            min-height: unset;
                        }

                        h5{
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            margin-bottom: 14px;
                            padding: 0 10px;

                            @include tablet{
                                font-size: 16px;
                            }
                        }

                        img{
                            width: 100%;
                            height: 223px;
                        }

                        p{
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            text-align: right;
                            margin-top: 9px;
                        }
                    }

                    &__upload{
                        width: 193px;
                        min-height: 222px;
                        padding: 15px 16px;
                        background-color: $color-main-back;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-radius: 10px;
                        cursor: pointer;
                        object-fit: contain;
                        word-wrap: break-word;

                        @include tablet{
                            width: 48%;
                        }
                        
                        img{
                            width: 70%;
                        }

                        h6{
                            font-weight: 400;
                            font-size: 15px;
                            margin-top: 9px;
                            margin-bottom: 6px;
                            text-align: center;
                            width: 100%;
                        }
                        
                        p{
                            text-align: center;
                            display: flex;
                            align-items: center;
                            margin-top: auto;

                            font-weight: 400;
                            font-size: 12px;
                            line-height: 24px;

                            img{
                                width: 17.46px;
                                height: 14.3px;
                                margin-right: 5px;
                                object-fit: contain;
                            }
                            
                            &:last-child{
                                justify-self: flex-end;
                                margin-top: 39px;
                            }
                        }
                    }
                }                
            }

            &--feedback{
                margin-top: 30px;

                h4{
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 28px;
                }

                textarea{
                    font-family: inherit;
                    padding: 21px 18px;
                    background-color: $color-main-back;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 23px;
                    width: 100%;
                    border-radius: 20px;
                    min-height: 155px;
                    margin-top: 46px;
                    border: none;
                    color: #dcdcdc;

                    @include tablet{
                        margin-top: 20px;
                    }

                    &::placeholder{
                        color: #dcdcdc;
                    }
                }

                button{
                    padding: 12px 19px;
                    line-height: unset;
                    font-size: 16px;
                }
            }
        }
    }

    &__stats{
        width: 28%;
        margin-right: 20px;
        
        @include tablet{
            width: 100%;
            display: none;
        }
        
        &__content{
            background-color: $color-main-back;
            border-radius: 18px;
            position: relative;
           
            &--scroll{
                overflow-y: scroll;
                height: 100vh;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 5px;
                }
    
                &::-webkit-scrollbar-track{
                    background: rgba(255, 255, 255, 0.1);
                }
    
                &::-webkit-scrollbar-thumb{
                    background: rgba($color-scroll-bar-thumb, 0.5);
                    border-radius:4px;
                }
            }

            h3{
                background-color: $color-main-back-light;
                padding: 27px 0 11px 22px;
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                border-radius: 18px 18px 0 0;
                position: sticky;
                top: 0;
                left: 0;
                width: 100%;

                @include tablet{
                    display: none;
                }
            }

            &--card{
                padding: 21px 15px 11px 15px;

                button{
                    padding: 10px 18px;
                    color: #fff;
                    background-color: $color-main-blue;
                    outline: none;
                    border: none;
                    border-radius: 10px;
                    font-size: 15px;
                    cursor: pointer;
                }

                h4{
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 25px;
                    width: fit-content;
                    height: fit-content;
                    background-color: $color-background-blue;
                    padding: 4px 0 11px 9px;
                    border-bottom: 1px solid $color-border-silver;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    width: 100%;

                    img{
                        width: 17px;
                        height: 8px;
                        object-fit: contain;
                        margin-right: 6px;
                        margin-left: 10px;
                    }
                }

                &--content{
                    display: flex;
                    align-items: flex-start;
                    padding: 13px 0 8px 0;
                    cursor: pointer;

                    &:not(:last-child){
                        border-bottom: 1px solid $color-border-silver;
                    }

                    img{
                        width: 18px;
                        height: 18px;
                        object-fit: contain;
                        margin-right: 19px;
                    }

                    h5{
                        font-weight: 400;
                        font-size: 14px;
                    }

                    &--certificate{
                        &--main{
                            width: 100% !important;
                            height: unset !important;
                            object-fit: contain !important;
                            margin-right: 0 !important;
                            margin-top: 18px !important;
                        }
                        div{
                            margin-top: 15px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            a{
                                display: flex;
                                align-items: center;
                                font-size: 15px;
                                color: #fff;
                                text-decoration: none;
                                img{
                                    height: 20px;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }

                    &--play{
                        margin-top: 10px;
                        font-weight: 400;
                        font-size: 15px;

                        img{
                            width: 18px;
                            height: 18px;
                            object-fit: contain;
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }

    &__assignment{
        display: flex;
        flex-direction: column;
        row-gap: 31px;

        &__no{
            font-size: 17px;
            font-weight: 400;

        }
        
        &__card{
            background: #26282C;
            border-radius: 10px;
            width: 100%;
            padding: 23px 16px;
            cursor: pointer;

            @include tablet{
                padding: 10px 15px;
            }

            &__container{
                display: flex;
                align-items: center;
                column-gap: 18px;

                @include tablet{
                    column-gap: 25px;
                    flex-wrap: wrap;
                    row-gap: 20px;
                    overflow: hidden;
                }
            }

            &__trainer{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 10%;

                @include tablet{
                    width: 20%;
                }

                img{
                    width: 50px;
                    height: 50px;
                    border-radius: 55px;
                    object-fit: contain;

                    @include tablet{
                        width: 20px;
                        height: 20px;
                    }
                }
                h6{
                    text-align: center;
                    margin-top: 7px;
                }
            }

            &__content{
                width: 22%;

                @include tablet{
                    width: 35%;
                }

                h4{
                    font-size: 17px;
                    font-weight: 500;
                }
                h5{
                    font-weight: 400;
                    font-size: 14px;
                    text-transform: capitalize;
                    margin-top: 15px;
                    word-break: break-all;
                }
            }

            &__deadline{
                width: 17%;

                @include tablet{
                    width: 25%;
                }
            }

            &__status{
                width: 17%;

                @include tablet{
                    width: 35%;
                }

                h4,h5,h6{
                    width: 100%;
                    font-weight: 600;
                    font-size: 15px;
                    border-radius: 5px;
                    padding: 7px 6px;
                    text-align: center;
                }

                h6{
                    background-color: $color-Inprogress;
                }

                h4{
                    background-color: $color-Approve;
                }

                h5{
                    background-color: $color-Assign;
                }

            }

            &__percentage{
                width: 19%;
                div{
                    height: 4px;
                    width: 100%;
                    border-radius: 5px;
                    background-color: $color-percentage-background;
                    overflow: hidden;

                    span{
                        border-radius: inherit;
                        height: 4px;
                        width: 0%;
                        display: block;
                        background-color: $color-main-blue;
                    }
                }
                h5{
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 12px;
                }
            }

            &__arr{
                margin-left: auto;
                img{
                    width: 17px;
                    height: 8px;
                    object-fit: contain;
                }
            }

            &__active{
                margin-top: 31px;
                padding-left: calc(10% + 18px);
                
                h4{
                    font-size: 18px;
                    font-weight: 500;
                }

                &__assets{
                    p{
                        background-color: rgba(255, 255, 255, 0.3);
                        padding: 8px 18px;
                        width: fit-content;
                        border-radius: 10px;
                        margin-top: 12px;
                        cursor: pointer;

                        @include tablet{
                            word-break: break-all;
                        }
                    }
                }

                &__submission{
                    margin-top: 30px;
                    p{
                        background-color: rgba(255, 255, 255, 0.3);
                        padding: 8px 18px;
                        width: fit-content;
                        border-radius: 10px;
                        margin-top: 12px;
                        cursor: pointer;
                        word-break: break-all;
                    }
                }

                &__upload{
                    margin-top: 5px;
                    
                    input{
                        display: none;
                    }

                    button{
                        line-height: unset;
                        font-size: 16px;
                        margin: 15px 0;
                        margin-bottom: 7px;
                    }

                    &__file{
                        background-color: transparent;
                        border: 1px solid $color-main-blue;
                        margin-right: 18px !important;
                    }

                    p{
                        font-size: 15px;
                        font-weight: 600;
                        color: $color-main-blue;
                    }
                }

                &__comment{
                    margin-top: 15px;
                    p{
                        border: 2px solid #1B5BD9;
                        border-radius: 5px;
                        min-height: 60px;
                        margin-top: 20px;
                        width: 95%;
                        padding: 5px 10px;
                        
                        ul{
                            font-weight: 400;
                            font-size: 16px;
                            list-style: inside;
                            span{
                                font-size: 12px;
                                color: rgba(255, 255, 255, 0.3);
                            }
                        }
                    }
                }

            }

        }
    }
}